import PropTypes from 'prop-types'
import React, {Component} from 'react';
import styles from './LanguageSelector.module.css';
import {LANGUAGE_DUTCH, LANGUAGE_ENGLISH} from "../../../Constants/language_constants";
import {withRouter} from "react-router";
import {create_change_language_url, get_language_from_url} from "../../../utilities";

class LanguageSelector extends Component {
    /* Component to create the language switcher, to switch the language between dutch and language
    * */

    set_language_to_eng = () => {
        // Method to set the language to english
        const url = create_change_language_url(LANGUAGE_ENGLISH, this.props);
        this.props.history.push(url);
    };

    set_language_to_nl = () => {
        // Method to set the language to dutch
        const url = create_change_language_url(LANGUAGE_DUTCH, this.props);
        this.props.history.push(url);
    };

    render() {
        const {className} = this.props;
        // get the current language
        const language = get_language_from_url(this.props);

        // Get the styles of the selector
        const container_styles = [styles.selector, className];
        const current_selector_styles = [styles.selector_current];
        const lang_nl_styles = [styles.language_label];
        const lang_en_styles = [styles.language_label];
        current_selector_styles.push(styles.selector_nl);
        if(language === LANGUAGE_ENGLISH) {
            current_selector_styles.push(styles.selector_nl_changed);
            lang_en_styles.push(styles.language_label_active);
        } else {
            lang_nl_styles.push(styles.language_label_active);
        }

        //Return the selector
        return (
           <div className={container_styles.join(" ")}>
               <div className={styles.selector_background} />
               <div className={current_selector_styles.join(" ")} />
               <div className={styles.language_overlay}>
                   <div className={lang_nl_styles.join(" ")} onClick={this.set_language_to_nl}>NL</div>
                   <div className={lang_en_styles.join(" ")} onClick={this.set_language_to_eng}>ENG</div>
               </div>
           </div>
       )
    }
}

export default withRouter(LanguageSelector);

LanguageSelector.propTypes = {
  className: PropTypes.string // style classname used for the selector
}
