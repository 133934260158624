import React, {Component}  from 'react';
import {withRouter} from "react-router";
import {
    check_not_empty,
    get_language_from_props,
    get_language_from_url,
    get_text
} from "../../../utilities";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Markup} from "interweave";

class TextLabel extends Component {
    /* Component to show a text. This component automatically handle the multi language functionality if the input is
    given as object with the language names as keys and the corresponded text as value.
    The component can also get text from the general text part of the settings file,
    can format the text in various ways and can replace the shortcodes inside the text, including preprocessing
    of the replace values of the short codes.
    If the value or the result of the id search resulted in a string, than the string is given. If it results in an
    object with the language names as keys, than the text is used corresponded to the current selected language

    id: The id of the text inside the given object or inside the general texts inside the settings file. Can be a
    string or as a list where the ids are nested in multiple objects:
    {"key_1": {"key_2": {"key_3": "text or language object"}}} can be given as ["key_1", "key_2", "key_3"]
    use_general: use the text located in the general part of the settings file

    The following options are available for preprocessing the text and shortcode values
    -as_number: format the input value as number, decimal separator will be based on the language
    -suffix: add a suffix before the value
    -default_value: show this text if the given id not exits in the object or general text object
    -add_thousands_separator: add thousands separators to the input value
    -number_as_string: replace the number to the corresponded word string (1 = One, 2 = Two etc)
    -process_html: process the html tags inside the value as html
    -first_letter_capital: make the first letter of the value capital
    -replace_values: replace the given shortcodes with other values. Must be given as object with the shortcode names as
    keys and the replace values as labels. The labels can also be a object which must contain the "value" variable
    with the replace value and can contain other text preprocessing options which are also available to process the
    value of this component itself, for example: "as_number", "add_thousands_separator" etc

    * */

    render() {
        const {children, content, use_general, as_number, suffix, id, default_values, replace_values,
            general, add_thousands_separator, number_as_string, process_html, first_letter_capital} = this.props;
        // get the current selected language from the props
        let language = get_language_from_props(this.props, false);
        if(check_not_empty(language) === false) {
            // get the language from the url if the language from the props is not available
            language = get_language_from_url(this.props);
        }
        // Check if the inpu data is given or that the general texts from the json file must be used
        let input_data;
        if(content !== undefined && content !== null && content !== "") {
            input_data = content;
        } else {
            input_data = children;
        }
        if(input_data === 'general' || input_data === 'g' || (use_general !== undefined && use_general ===  true)) {
            input_data = general;
        }

        // process the text
        let processed_text = get_text(input_data, id, default_values, language, general, replace_values, suffix, as_number, add_thousands_separator, number_as_string, first_letter_capital);

        // No text is found, return null
        if(typeof processed_text !== 'string') {
            return null;
        }

        // the html inside the text must be processed, so process the html code
        if(process_html !== undefined && process_html === true) {
            return <Markup content={processed_text}/>
        }

        // return the text without html processing
        return processed_text;
    }
}

const mapStateToProps = (state) => {
    return {
        general: state.coronaBarometerReducer.general,
    }
};

export default withRouter(connect(mapStateToProps)(TextLabel));
export const TextLabelWithoutRedux = TextLabel;

TextLabel.propTypes = {
    suffix: PropTypes.string,
    id: PropTypes.any,
    use_general: PropTypes.bool,
    as_number: PropTypes.bool,
    content: PropTypes.any,
    default_values: PropTypes.string,
    replace_values: PropTypes.object,
    general: PropTypes.object,
    match: PropTypes.object,
    add_thousands_separator: PropTypes.bool,
    number_as_string: PropTypes.bool,
    first_letter_capital: PropTypes.bool,
    process_html: PropTypes.bool
};
