import PropTypes from 'prop-types';
import React, {Component} from 'react';
import BaseBlock from "../BaseBlock/BaseBlock";
import BlockHeader from "../../UI/BlockElements/BlockHeader/BlockHeader";
import BlockPieChart from "../../UI/BlockElements/BlockPieChart/BlockPieChart";
import {check_not_empty, get_language_from_props, isMobile} from '../../../utilities'
import {
    get_block_categories,
    get_filter_key,
    get_latest_data_week_key,
    round_number,
    updateObject
} from "../../../utilities";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import Loader from "../../UI/Loader/Loader";
import TextLabel from "../../SupportedComponents/TextLabel/TextLabel";

// Default block object settings which can be overridden in the website setup json file
const default_block_data = {
    icon: null,
    title: null,
    category: null,
    description: null,
    graph: {
        data_key: "value",
        total_pie_size: 100,
    },
};

class PieChartBlock extends Component {
    /*
    * Component to create a block with a pie chart. Component will be used if the block type is set to "pie_chart".
    * */

    render() {
        const {block, active_data_filters, block_filters, blocks_data, week_ids, blocks_data_loaded} = this.props;
        // Merge the default block settings with the settings from the json settings file
        const block_merged = updateObject(default_block_data, {
            ...block,
            graph: updateObject(default_block_data.graph, block.graph)
        });

        // Get current selected language from the router props
        const language = get_language_from_props(this.props);

        // Get classes
        const gridItemClassName = get_block_categories(block_merged, true);

        // Show by default the loader
        let block_content = <Loader margin_top={125} />;

        // Show block if the block data is loaded
        if(blocks_data_loaded) {
            // Get the filter key of the active filters
            const filter_key = get_filter_key(active_data_filters, block_filters);

            // Get the block data
            const block_data = blocks_data[filter_key][block_merged.id];
            if(block_data === undefined) {
                // Hide the block by adding the "hide_block" class to the grid classes
                gridItemClassName.push("hide_block");
            } else {
                // Get the most recent week key available for this statistic
                const cur_week_key = get_latest_data_week_key(block_data, week_ids);
                if (cur_week_key === null) {
                    // Hide the block by adding the "hide_block" class to the grid classes
                    gridItemClassName.push("hide_block");
                } else {
                    const updated_data = [];

                    //
                    // Process the data from the database or input file so that it can be used in the pie chart
                    //

                    // One of the groups can be the rest of the leftover value. For example the total pie chart is
                    // 100% and the number of woman is 50% than the left over value will be 50%
                    let rest_number = block_merged.graph.total_pie_size;
                    // Data row of the left over value
                    let rest_data_row = null;

                    // Get the most recent data
                    const current_data = block_data[cur_week_key];

                    // Loop though all the groups in de pie chart
                    block_merged.graph.data.forEach((data_row) => {
                        if(data_row.hasOwnProperty('id')) {
                            const row_id = data_row['id'];
                            if(current_data[row_id] !== undefined) {
                                // Process the value
                                const current_data_row = {...data_row};
                                const value = round_number(current_data[row_id]);
                                rest_number -= value; // substract the current value from the leftover value
                                current_data_row[block_merged.graph.data_key] = value;
                                updated_data.push(current_data_row);
                            }
                        }

                        // Process the leftover group
                        if(data_row.hasOwnProperty('rest') && data_row["rest"] === true) {
                            const current_data_row = {...data_row};
                            current_data_row[block_merged.graph.data_key] = 0;
                            rest_data_row = current_data_row;
                            updated_data.push(current_data_row);
                        }
                    });

                    // Add the leftover value to the leftover group
                    if(rest_data_row !== null) {
                        rest_data_row[block_merged.graph.data_key] = round_number(rest_number);
                    }
                    block_merged.graph = {
                        ...block_merged.graph,
                        data: updated_data
                    };
                }
            }

            // Create the JSX code of the content of the block
            block_content = <React.Fragment>
                <BlockHeader
                    icon={block_merged.icon}
                    description={check_not_empty(block_merged.description) ? <TextLabel content={block_merged.description} process_html /> : null}
                >
                    <TextLabel content={block_merged.title} />
                </BlockHeader>
                <BlockPieChart data={block_merged.graph} language={language} />
            </React.Fragment>
        }
        // Return the block content with the basic block wrapper
        return (
            <BaseBlock
                gridItemClassName={gridItemClassName.join(" ")}
                width_size={isMobile ? 2 : 1}
                auto_height={isMobile}
            >
                {block_content}
            </BaseBlock>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        blocks_data_loaded: state.coronaBarometerReducer.blocks_data_loaded,
        active_data_filters: state.coronaBarometerReducer.active_data_filters,
        block_filters: state.coronaBarometerReducer.block_filters,
        blocks_data: state.coronaBarometerReducer.blocks_data,
        week_ids: state.coronaBarometerReducer.week_ids,
    }
};


export default withRouter(connect(mapStateToProps)(PieChartBlock));


PieChartBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired, // number of the block on the blocks page
    block: PropTypes.object.isRequired, // Object of the block from the json setup file
};
