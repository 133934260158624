import React, { Component } from 'react';
import PropTypes from "prop-types";
import styles from './SidebarToggleTitle.module.css';
import {check_not_empty} from "../../../../../utilities";
import Icon from "../../../../UI/Icon/Icon";

class SidebarToggleTitle extends Component {
    /*  Component for the titles of the different parts of the sidebar which also functions as toggle buttons
    * */
    constructor(props) {
        super(props);
        // State to manage the hover state
        this.state = {
            hovered: false
        }
    }

    set_hover(hovered=true) {
        // Method to change the hover state
        this.setState({
            hovered: hovered
        })
    };

    render() {
        const {icon, title, onClick, collapsed, show_top_line} = this.props;
        const block_classes = [styles.content];

        // add a top line to the title
        if(show_top_line) {
            block_classes.push(styles.line_top);
        }

        // Render the title
        return (
            <div onClick={onClick}
                 onMouseEnter={() => this.set_hover(true)}
                 onMouseLeave={() => this.set_hover(false)}
                 className={block_classes.join(" ")}>
                {check_not_empty(icon) ? <Icon icon={icon} className={styles.icon} /> : null}
                <span className={styles.title}>{title}</span>
                 <Icon icon={collapsed ? 'min' : 'plus'}
                       icon_hover={collapsed ? 'min_hover' : 'plus_hover'}
                       is_hovered={this.state.hovered}
                       className={styles.toggle_icon} />
            </div>
        )
    }
}

export default SidebarToggleTitle;

SidebarToggleTitle.propTypes = {
    icon: PropTypes.string, // Icon which will be added before the title
    title: PropTypes.object.isRequired, // the title text
    onClick: PropTypes.func.isRequired, // the Onlick function reference
    collapsed: PropTypes.bool, // if the block is collapsed or not
    show_top_line: PropTypes.bool // show a line above the title
};
