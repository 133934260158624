import * as actionTypes from './actionTypes'
import {get_filter_key, getActionObject} from "../../utilities";
import * as blocks_data from '../../blocks_data';
import * as website_data from '../../website_data';
import {
    firestore_get_blocks_by_filter_key
} from "../../services/firestore";

const set_general_info = (general) => getActionObject(actionTypes.CORONA_BAROMETER_SET_GENERAL_INFO, {general: general});
const set_pages = (pages) => getActionObject(actionTypes.CORONA_BAROMETER_SET_PAGES, {pages: pages});
const set_blocks = (blocks) => getActionObject(actionTypes.CORONA_BAROMETER_SET_BLOCKS, {blocks: blocks});
const set_data_categories = (data_categories) => getActionObject(actionTypes.CORONA_BAROMETER_SET_DATA_CATEGORIES, {data_categories: data_categories});
const set_blocks_data = (blocks_data) => getActionObject(actionTypes.CORONA_BAROMETER_SET_BLOCKS_DATA, {blocks_data: blocks_data});
const set_is_loaded = () => getActionObject(actionTypes.CORONA_BAROMETER_SET_LOADED, {loaded: true});
const set_current_week = (current_week) => getActionObject(actionTypes.CORONA_BAROMETER_SET_CURRENT_WEEK, {current_week: current_week});
const set_week_ids = (week_ids) => getActionObject(actionTypes.CORONA_BAROMETER_SET_WEEK_IDS, {week_ids: week_ids});
const set_block_filters = (block_filters) => getActionObject(actionTypes.CORONA_BAROMETER_SET_BLOCK_FILTERS, {block_filters: block_filters});
export const set_active_data_filters = (active_data_filters) => getActionObject(actionTypes.CORONA_BAROMETER_SET_ACTIVE_DATA_FILTERS, {active_data_filters: active_data_filters});
export const set_selected_data_filters = (selected_data_filters) => getActionObject(actionTypes.CORONA_BAROMETER_SET_SELECTED_DATA_FILTERS, {selected_data_filters: selected_data_filters});
export const set_default_filter_id = (default_filter_id) => getActionObject(actionTypes.CORONA_BAROMETER_SET_DEFAULT_FILTER_ID, {default_filter_id: default_filter_id});
const set_block_footer_reference_type = (block_footer_reference_type) => getActionObject(actionTypes.CORONA_BAROMETER_SET_BLOCK_FOOTER_REFERENCE_TYPE, {block_footer_reference_type: block_footer_reference_type});
const set_block_footer_reference_type_by_user = (block_footer_reference_type) => getActionObject(actionTypes.CORONA_BAROMETER_SET_BLOCK_FOOTER_REFERENCE_TYPE_BY_USER, {block_footer_reference_type: block_footer_reference_type});
const set_blocks_data_loaded = (blocks_data_loaded) => getActionObject(actionTypes.CORONA_BAROMETER_SET_BLOCKS_DATA_LOADED, {blocks_data_loaded: blocks_data_loaded});
const set_blocks_data_loaded_selected = (blocks_data_loaded_selected) => getActionObject(actionTypes.CORONA_BAROMETER_SET_BLOCKS_DATA_LOADED_SELECTED, {blocks_data_loaded_selected: blocks_data_loaded_selected});
export const corona_barometer_set_active_sidebar_item = (active_sidebar_item) => getActionObject(actionTypes.CORONA_BAROMETER_SET_ACTIVE_SIDEBAR_ITEM, {active_sidebar_item: active_sidebar_item});

export const corona_barometer_set_active_data_filters = (active_data_filters) => {
    // method to set the active data filters, set in the sidebar
    return (dispatch) => {
        dispatch(update_block_data_from_firestore_if_nessesary(active_data_filters, false));
        dispatch(set_active_data_filters(active_data_filters));
    }
};

export const corona_barometer_set_selected_data_filters = (selected_data_filters) => {
    // method to set the selected data filters set by the modal
    return (dispatch) => {
        dispatch(update_block_data_from_firestore_if_nessesary(selected_data_filters, true));
        dispatch(set_selected_data_filters(selected_data_filters));
    }
};

const update_block_data_from_firestore_if_nessesary = (data_filters, selected_filter=false) => {
    // get the data from the firestore database if the data is not already downloaded
    return (dispatch, get_state) => {
        const application_state = get_state().coronaBarometerReducer;
        const blocks_data = application_state.blocks_data;
        const block_filters = application_state.block_filters;
        const filter_key = get_filter_key(data_filters, block_filters);
        if(blocks_data.hasOwnProperty(filter_key) === false) {
            if(selected_filter) {
                dispatch(set_blocks_data_loaded_selected(false));
            } else {
                dispatch(set_blocks_data_loaded(false));
            }
            // get the data from the firestore database
            firestore_get_blocks_by_filter_key(filter_key).then((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    const updated_data = {
                        ...blocks_data,
                        [filter_key]: data
                    };
                    dispatch(set_blocks_data(updated_data));
                }
                if(selected_filter) {
                    dispatch(set_blocks_data_loaded_selected(true));
                } else {
                    dispatch(set_blocks_data_loaded(true));
                }
            })
        }
    }
};


export const corona_barometer_init_data_filters = (init_active_filter = true,
                                                   init_selected_filter = true) => {
    // initialise the data filters in the redux store
    return (dispatch, get_state) => {
        const block_filters = get_state().coronaBarometerReducer.block_filters;
        const init_block_filters = {}
        block_filters.forEach((filter) => {
            init_block_filters[filter.id] = null
        });
        if(init_active_filter) {
            dispatch(set_active_data_filters(init_block_filters))
        }
        if(init_selected_filter) {
            dispatch(set_selected_data_filters(init_block_filters))
        }
    }
}

export const init_default_filter_id = () => {
    // set the default key value in the redux store used to get the init values and the average values
    return (dispatch, get_state) => {
        const block_filters = get_state().coronaBarometerReducer.block_filters;
        const default_filter_id = "NA" + ".NA".repeat(block_filters.length - 1);
        dispatch(set_default_filter_id(default_filter_id));
    }
}


export const corona_barometer_init = () => {
    // initialise the corona barometer redux store
    return (dispatch) => {
        dispatch(set_general_info(website_data.general));
        dispatch(set_pages(website_data.pages));
        const blocks = {...website_data.data_blocks};
        Object.keys(blocks).forEach((block_key) => {
            const current_block_object = blocks[block_key];
            if(current_block_object["type"] === "group") {
                if(current_block_object.hasOwnProperty("blocks")) {
                    const group_blocks = current_block_object["blocks"];
                    Object.values(group_blocks).forEach(single_block => {
                        blocks[single_block.id] = single_block;
                    });
                }
            }
        });
        dispatch(set_blocks(blocks));
        dispatch(set_data_categories(website_data.data_categories));
        dispatch(set_block_filters(website_data.filters));

        const week_ids = Array(website_data.general.max_week_number).fill().map((_, i) => {return `week_${i + 1}`});
        dispatch(set_week_ids(week_ids));
        dispatch(set_current_week(website_data.general.max_week_number));

        const blocks_data_obj = {...blocks_data}.default;
        dispatch(set_blocks_data(blocks_data_obj));

        dispatch(set_blocks_data_loaded(true));
        dispatch(set_is_loaded());

        dispatch(init_default_filter_id());
        dispatch(corona_barometer_init_data_filters());

    }
};


export const  corona_barometer_set_block_footer_reference_type = (footer_reference_type, by_user=true) => {
    // method to set the block footer reference type
    return (dispatch) => {
        dispatch(set_block_footer_reference_type(footer_reference_type));
        if(by_user) {
            dispatch(set_block_footer_reference_type_by_user(footer_reference_type));
        }
    }
};
