import PropTypes from 'prop-types'
import React, { Component } from 'react';
import {check_not_empty, get_object_from_list} from "../../../utilities";
import styles from "./FilterGroupButtons.module.css";
import FilterButton from "../FilterButton/FilterButton";
import TextLabel from "../../SupportedComponents/TextLabel/TextLabel";


class FilterGroupButtons extends Component {
    /* Component to create filter group which enable filtering on a specific characteristic
    (age, living situation, etc). This component shows the title and the filterbutton.
    Button clicks are handled by the external onClick method
    *
    * */

    onChange = (id) => {
        // Method to handle the clicks on the filter button. The method uses the onClick
        // function reference to forward the filter click event
        const {active_filter, onChange, filter} = this.props;
        if(active_filter[filter.id] === id) {
            onChange(filter.id, null);
        } else {
            onChange(filter.id, id);
        }
    };

    render() {
        const {filters, filter, active_filter, small_buttons} = this.props;
        if(check_not_empty(filters) === false) {
            return null;
        }

        // The filter will be disabled if the filter is exclusive (cannot combined with other filters) and another
        // filter is selected or the other filter is exclusive and cannot combined with this filter
        let disabled = false;
        let other_fiter_selected = false;
        // Loop through all the current active filters
        Object.keys(active_filter).forEach((act_key) => {
            const act_value = active_filter[act_key];
            if(check_not_empty(act_value)) {
                // Another filter is active
                other_fiter_selected = true;
                // check if the active filter is an exclusive filter
                if(act_key !== filter.id) {
                    const curr_filter = get_object_from_list(filters, act_key);
                    if(curr_filter["exclusive"] !== undefined && curr_filter["exclusive"] === true) {
                        disabled = true
                    }
                }
            }
        })

        // Disable the filter, if another filter is selected and this filter is exclusive
        if(other_fiter_selected === true && check_not_empty(active_filter[filter.id]) === false && filter["exclusive"] !== undefined && filter["exclusive"] === true) {
            disabled = true;
        }

        // Create the filter button group
        return <div className={styles.filter_group}>
            {/* Add the title */}
            {check_not_empty(filter.title) ? <span className={styles.filter_title}>
                <TextLabel content={filter.title} />
            </span> : null}
            {/* Create the filter buttons */}
            {filter.options.map((filter_info, index) => {
                return <FilterButton key={index}
                                     onClick={() => this.onChange(filter_info.id)}
                                     active={active_filter[filter.id] === filter_info.id}
                                     small={small_buttons}
                                     disabled={disabled}
                >
                    <TextLabel content={filter_info.label} />
                </FilterButton>
            })}
        </div>
    }
}
export default FilterGroupButtons;


FilterGroupButtons.propTypes = {
  active_filter: PropTypes.object.isRequired, // Object with the active filters (filter id as key and selected option as value
  filters: PropTypes.array.isRequired, // list with the filters
  filter: PropTypes.object.isRequired, // the filter which must be rendered
  onChange: PropTypes.func.isRequired, // reference to the onclick function
  small_buttons: PropTypes.bool, // show small buttons
};
