// Icon page
export const ICONS_SET_ICONS = "ICONS_SET_ICONS";

// Corona barometer
export const CORONA_BAROMETER_SET_GENERAL_INFO = "CORONA_BAROMETER_SET_GENERAL_INFO";
export const CORONA_BAROMETER_SET_PAGES = "CORONA_BAROMETER_SET_PAGES";
export const CORONA_BAROMETER_SET_LANGUAGE = "CORONA_BAROMETER_SET_LANGUAGE";
export const CORONA_BAROMETER_SET_BLOCKS = "CORONA_BAROMETER_SET_BLOCKS";
export const CORONA_BAROMETER_SET_DATA_CATEGORIES = "CORONA_BAROMETER_SET_DATA_CATEGORIES";
export const CORONA_BAROMETER_SET_BLOCKS_DATA = "CORONA_BAROMETER_SET_BLOCKS_DATA";
export const CORONA_BAROMETER_SET_LOADED = "CORONA_BAROMETER_SET_LOADED";
export const CORONA_BAROMETER_SET_WEEK_IDS = "CORONA_BAROMETER_SET_WEEK_IDS";
export const CORONA_BAROMETER_SET_CURRENT_WEEK = "CORONA_BAROMETER_SET_CURRENT_WEEK";
export const CORONA_BAROMETER_SET_BLOCK_FILTERS = "TIME_OVERVIEW_MODEL_SET_BLOCK_FILTERS";
export const CORONA_BAROMETER_SET_ACTIVE_DATA_FILTERS = "TIME_OVERVIEW_MODEL_SET_ACTIVE_DATA_FILTERS";
export const CORONA_BAROMETER_SET_SELECTED_DATA_FILTERS = "TIME_OVERVIEW_MODEL_SET_SELECTED_DATA_FILTERS";
export const CORONA_BAROMETER_SET_DEFAULT_FILTER_ID = "CORONA_BAROMETER_SET_DEFAULT_FILTER_ID";
export const CORONA_BAROMETER_SET_BLOCK_FOOTER_REFERENCE_TYPE = "CORONA_BAROMETER_SET_BLOCK_FOOTER_REFERENCE_TYPE";
export const CORONA_BAROMETER_SET_BLOCK_FOOTER_REFERENCE_TYPE_BY_USER = "CORONA_BAROMETER_SET_BLOCK_FOOTER_REFERENCE_TYPE_BY_USER";
export const CORONA_BAROMETER_SET_BLOCKS_DATA_LOADED = "CORONA_BAROMETER_SET_BLOCKS_DATA_LOADED";
export const CORONA_BAROMETER_SET_BLOCKS_DATA_LOADED_SELECTED = "CORONA_BAROMETER_SET_BLOCKS_DATA_LOADED_SELECTED";
export const CORONA_BAROMETER_SET_ACTIVE_SIDEBAR_ITEM = "CORONA_BAROMETER_SET_ACTIVE_SIDEBAR_ITEM";

// Time overview Modal
export const TIME_OVERVIEW_MODEL_SET_IS_OPEN = "TIME_OVERVIEW_MODEL_SET_IS_OPEN";
export const TIME_OVERVIEW_MODEL_SET_MAIN_BLOCK_ID = "TIME_OVERVIEW_MODEL_SET_MAIN_BLOCK_ID";
export const TIME_OVERVIEW_MODEL_SET_SELECTION_TYPE = "TIME_OVERVIEW_MODEL_SET_SELECTION_TYPE";
export const TIME_OVERVIEW_MODEL_SET_SELECTED_BLOCK_ID = "TIME_OVERVIEW_MODEL_SET_SELECTED_BLOCK_ID";

