import * as actionTypes from './actionTypes'
import {getActionObject} from "../../utilities";

const set_icons = (icons_obj) => getActionObject(actionTypes.ICONS_SET_ICONS, {icons: icons_obj});

export const icons_init = () => {
    // initialise the icons
    return (dispatch) => {
        const importAll = require =>
            require.keys().reduce((acc, next) => {
                let file_name = next.replace("./", "");
                file_name = file_name.split(".").slice(0,-1).join(".");
                acc[file_name] = require(next);
                return acc;
            }, {});

        const images = importAll(
            require.context("./../../../public/icons", false, /\.(png|jpe?g|svg)$/)
        );
        return dispatch(set_icons(images));
    }
};
