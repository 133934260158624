import React, { Component } from 'react';
import styles from './ResetDataButton.module.css';
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";

class ResetDataButton extends Component {
    /* Component to create the reset button of the filters */
    constructor(props) {
        super(props);
        // process hover effect
        this.state = {
            hovered: false
        }
    }

    set_hover(hovered=true) {
        // method to toggle the hover effect
        this.setState({
            hovered: hovered
        })
    };

    render() {
        const {onClick, disabled, children} = this.props;

        // handle style and disabled
        const button_classes = [styles.button];
        if(!disabled) {
            button_classes.push(styles.clickable);
        }

        // create the button
        return (
            <div
                onClick={!disabled ? onClick :  null}
                onMouseEnter={() => this.set_hover(true)}
                onMouseLeave={() => this.set_hover(false)}
                className={button_classes.join(" ")}>
                <Icon icon={!disabled ? "cross" : "cross-disabled"}
                      icon_hover={!disabled ? "cross_hover" : null}
                      className={styles.icon}
                      is_hovered={!disabled ? this.state.hovered : null}
                />
                <span>{children}</span>
            </div>
        )
    }
}

export default ResetDataButton;


ResetDataButton.propTypes = {
    children: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
}
