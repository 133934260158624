import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import styles from "./ComparisonBarGraph.module.css";
import {
    check_na_value,
    check_not_empty,
    get_filter_key, get_filter_string, get_language_from_url,
    get_latest_data_week_key, get_text, has_filter, is_same_filter,
    isMobile,
    round_number,
    updateObject
} from "../../../../utilities";
import BlockBarGraph from "../../BlockElements/BlockBarGraph/BlockBarGraph";
import {
    GRAPH_SELECTION_TYPE_FILTER,
    GRAPH_SELECTION_TYPE_MEAN
} from "../../../../Constants/graph_selection_type_constants";
import Loader from "../../Loader/Loader";
import MessageBox from "../../MessageBox/MessageBox";
import TextLabel from "../../../SupportedComponents/TextLabel/TextLabel";
import {withRouter} from "react-router";

// Default graph object settings which can be overridden in the website setup json file
const default_block_data = {
    block_data: null,
    block_data_keys: null,
    icon: null,
    title: null,
    category: null,
    description: null,
    graph: {},
    graph_data_value_key: "value",
    number_sub_text: null,
    reference: null,
    reference_color: "neutral",
    reference_arrow: null,
};


class ComparisonBarGraph extends Component {
    /* Component to show a comparison bar graph in the modal or mobile page to compare multiple filters with each other
    * */
    constructor(props) {
        super(props);
        // state used to handle message that the data is not available
        this.state = {
            show_not_available_message: false,
        }
    }

    set_data_not_available_message() {
        // set data not available message
        this.setState({show_not_available_message: true});
        setTimeout(() => this.setState({show_not_available_message: false}), 5000);
    }

    render() {
        const {main_block_id, selected_data_filters, blocks, blocks_data,
            week_ids, active_data_filters, default_filter_id, general, selection_type, block_filters,
            blocks_data_loaded} = this.props;
        // Get the filter key of the active filters
        const filter_key = get_filter_key(active_data_filters, block_filters);
        // get the block of the main data
        const main_block = blocks[main_block_id];
        // Get current selected language from the url
        const language = get_language_from_url(this.props);
        let show_not_available_message = false;
        // Merge the default block settings with the settings from the json settings file
        const block_merged = updateObject(default_block_data, {
            ...main_block,
            graph: updateObject(default_block_data.graph, main_block.graph)
        });
        // Get the data of the main part of the graph
        const block_data = blocks_data[filter_key][block_merged.id];
        if(block_data === undefined || typeof block_merged.graph_data_value_key !== 'string') {
            return null;
        }
        // Get the most recent week key available for the main statistic
        const cur_week_key = get_latest_data_week_key(block_data, week_ids);
        if (cur_week_key === null) {
            return null;
        }
        // get hte main data of the most recent measure
        const current_data = block_data[cur_week_key];
        if(check_not_empty(current_data) === false  && Object.keys(current_data).length === 0) {
            return null;
        }

        // Find the data corresponded by the selected comparison filter
        let compare_block_data = null;
        let compare_title = "";
        let is_loading = false;
        if(selection_type !== null) {
            if(selection_type === GRAPH_SELECTION_TYPE_MEAN && has_filter(active_data_filters)) {
                // compare with the average

                // get the average data
                compare_block_data = blocks_data[default_filter_id][main_block_id][cur_week_key];
                // Get the graph bar title
                compare_title = get_text(general, "compare_mean", "", language);
            } else if(selection_type === GRAPH_SELECTION_TYPE_FILTER &&
                has_filter(selected_data_filters) &&
                is_same_filter(active_data_filters, selected_data_filters) === false) {
                // compare with another group (custom comparison filter)
                if(blocks_data_loaded) {
                    // get the filter key
                    const selected_filter_key = get_filter_key(selected_data_filters, block_filters);
                    if(blocks_data[selected_filter_key].hasOwnProperty(main_block_id)) {
                        // check if the data of the same week is available for this filter
                        if(blocks_data[selected_filter_key][main_block_id].hasOwnProperty(cur_week_key)) {
                            // Get the comparison data
                            compare_block_data = blocks_data[selected_filter_key][main_block_id][cur_week_key];
                            // Get the graph bar title
                            compare_title = get_filter_string(selected_data_filters, block_filters, language);
                        } else {
                            // Show data not available massage
                            if(this.state.show_not_available_message === false) {
                                show_not_available_message = true;
                            }
                        }
                    } else {
                        // Show data not available massage
                        if(this.state.show_not_available_message === false) {
                            show_not_available_message = true;
                        }
                    }
                } else {
                    is_loading = true;
                }
            }
        }

        // process the data that it can be used with the graph plugin
        const updated_data = [];
        block_merged.graph.data.forEach((data_row) => {
            if(data_row.hasOwnProperty('id')) {
                const current_data_row = {...data_row};
                if(current_data.hasOwnProperty(data_row['id'])) {
                    const cur_value = check_na_value(current_data[data_row['id']]);
                    // add the label of the bar
                    current_data_row["name"] = get_text(current_data_row["name"], null, null, language);
                    if(cur_value !== null) {
                        // add the data value
                        current_data_row[block_merged.graph_data_value_key] = round_number(cur_value);
                    }
                }
                if(check_not_empty(compare_block_data) && compare_block_data.hasOwnProperty(data_row['id'])) {
                    const value = check_na_value(compare_block_data[data_row['id']]);
                    if(value !== null) {
                        // process the compare value
                        current_data_row["compare_block"] = round_number(value);
                    }
                }
                updated_data.push(current_data_row);
            }
        });

        // process the height of the graph
        const block_style_classes = [styles.graph_block];
        let height = 450;
        if(isMobile) {
            block_style_classes.push(styles.graph_block_mobile);
            height = 450;
        }

        // list of all the bars of the graph
        let bars = [
            ...block_merged.graph.bars
        ];

        // add the comparison bar
        if(check_not_empty(compare_block_data)) {
            bars.push({
                "data_key": "compare_block",
                "color": "#afdae5",
                "label": compare_title,
                "unit": block_merged.graph.bars[0].unit
            });
        }

        // create the complete bar graph object
        block_merged.graph = {
            ...block_merged.graph,
            bars:bars,
            "height": height,
            data: updated_data
        };

        // create the comparison bar graph
        return <div className={block_style_classes.join(" ")}>
            <MessageBox show={show_not_available_message}>
                <TextLabel use_general id="filter_result_no_data" />
            </MessageBox>
            {is_loading ? <Loader margin_top={200} /> : <BlockBarGraph data={block_merged.graph}
                                                                       language={language}
                                                                       in_modal/>}
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        blocks_data_loaded: state.coronaBarometerReducer.blocks_data_loaded_selected,
        blocks: state.coronaBarometerReducer.blocks,
        blocks_data: state.coronaBarometerReducer.blocks_data,
        main_block_id: state.timeOverviewModalReducer.main_block_id,
        selected_block_id: state.timeOverviewModalReducer.selected_block_id,
        selected_data_filters: state.coronaBarometerReducer.selected_data_filters,
        default_filter_id: state.coronaBarometerReducer.default_filter_id,
        selection_type: state.timeOverviewModalReducer.selection_type,
        general: state.coronaBarometerReducer.general,
        active_data_filters: state.coronaBarometerReducer.active_data_filters,
        week_ids: state.coronaBarometerReducer.week_ids,
        block_filters: state.coronaBarometerReducer.block_filters,
    }
};


export default withRouter(connect(mapStateToProps)(ComparisonBarGraph));
