import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server'
import BaseBlock from "../BaseBlock/BaseBlock";
import {get_block_categories, isMobile, updateObject} from "../../../utilities";
import * as actions from "../../../store/actions";
import { connect } from 'react-redux';
import BarGraphBlock, {BarGraphBlockWithoutRedux} from "../BarGraphBlock/BarGraphBlock";
import {withRouter} from "react-router";
import PropTypes from "prop-types";

// Default block object settings which can be overridden in the website setup json file
const default_block_data = {
    blocks: {},
    category: null,
    block_width_size: 1,
    block_height_size: 1,
};

class GroupBlock extends Component {
    /*
    * Component to create a block which can contain multiple bar graph blocks.
    * Component will be used if the block type is set to "group".
    * This groupblock can be used which multiple bargraph blocks which has a auto weight. The blocks will than not
    * lined up with the next block height size but will be combined and the total group block will be lined up with
    * the next group block.
    *
    * The height of the group block can be indicated as single int for the total height or be dependend of the number
    * of active inner block graphs block by giving a object with as keys the number of active internal blocks and as
    * values the height of the group block
    * */

    render() {
        const {block, blockIndex, ...rest} = this.props;

        // Merge the default block settings with the settings from the json settings file
        const block_merged = updateObject(default_block_data, block);

        // Get classes
        const gridItemClassName = get_block_categories(block_merged, true);

        // Calculate the number of active internal blocks by pre-rendering
        let internal_blocks = Object.values(block_merged.blocks).map((block_data, dataIndex) =>
            ReactDOMServer.renderToStaticMarkup(<BarGraphBlockWithoutRedux {...rest} block={block_data}
                                          blockIndex={dataIndex} in_group_block={true} />));

        internal_blocks = internal_blocks.filter((int_html) => int_html !== null && int_html !== "")

        // Calculate the height of the group block
        let proc_height_size = block_merged.block_height_size;
        if(internal_blocks.length > 0) {
            if(typeof block_merged.block_height_size === "object") {
                proc_height_size = block_merged.block_height_size[internal_blocks.length.toString()]
            }
        } else {
            // Hide the group block if all the blocks inside the group block also be hided
            gridItemClassName.push("hide_block");
        }

        // Return the group block by wrapping the base block and render all the internal bar graph blocks
        return (
            <BaseBlock
                gridItemClassName={gridItemClassName.join(" ")}
                width_size={block_merged.block_width_size}
                height_size={proc_height_size}
                auto_height={isMobile}
                is_group_block={true}
            >
                {internal_blocks.length > 0 ? Object.values(block_merged.blocks).map((block_data, dataIndex) =>
                    <BarGraphBlock
                        key={dataIndex}
                        block={block_data}
                        blockIndex={dataIndex}
                        in_group_block={true} />) : null}
            </BaseBlock>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        blocks_data_loaded: state.coronaBarometerReducer.blocks_data_loaded,
        active_data_filters: state.coronaBarometerReducer.active_data_filters,
        block_filters: state.coronaBarometerReducer.block_filters,
        blocks_data: state.coronaBarometerReducer.blocks_data,
        week_ids: state.coronaBarometerReducer.week_ids,
        page_has_block_filters: state.coronaBarometerReducer.page_has_block_filters,
        icons: state.iconsReducer.icons
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        open_modal: (block) => dispatch(actions.time_overview_modal_set_modal_open(block)),
        icons_init: () => dispatch(actions.icons_init())
    }
};

BarGraphBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired, // number of the block on the blocks page
    block: PropTypes.object.isRequired, // Object of the block from the json setup file
};



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupBlock));
