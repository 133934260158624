import PropTypes from 'prop-types'
import React, {Component} from 'react';
import styles from './Blocks.module.css';
import GridContainer from "../../../Grid/GridContainer/GridContainer";
import BlockFactory from "../../../IndividualBlocks/BlockFactory/BlockFactory";
import FilterButton from "../../../UI/FilterButton/FilterButton";
import {check_not_empty, isMobile} from '../../../../utilities';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import TextLabel from "../../../SupportedComponents/TextLabel/TextLabel";

class Blocks extends Component {
    /* Component to show the part with the static blocks of a block page.
    This component add also the category selector and footer below the blocks
    * */

    constructor(props) {
        super(props);
        // state used to know the selected categories
        this.state = {
            selected_categories: []
        }
    }

    toggle_selected_category = (id) => {
        // Method to toggle (add / remove) the category selectors
        let filtered_categories;
        if(id === null) {
            // show all categories
            filtered_categories = []
        }
        else if(this.state.selected_categories.includes(id)) {
            // remove the category
            filtered_categories = []
        } else {
            // add the category
            filtered_categories = [id];
        }
        this.setState({
            selected_categories: filtered_categories
        })
    };

    get_filter_string = () => {
        // Method to create the category filter string
        if(this.state.selected_categories.length > 0) {
            // add the .all class to every filter string to show blocks with the all category at all times
            return [".all", this.state.selected_categories.map((item) => `.${item}`)].join(", ")
        }
        return ""
    };

    render() {
        const {blocks, category_ids, sidebar_open, sidebar_animation_ready,
            page_block_ids, modal_is_open, data_categories} = this.props;
        const used_styles = [styles.blocks];

        // handle the sidebar open / close styles
        if(sidebar_open) {
            used_styles.push(styles.sidebar_open);
        } else {
            used_styles.push(styles.sidebar_close);
            if(sidebar_animation_ready) {
                used_styles.push(styles.sidebar_close_ready)
            }
        }

        // styles of the category filter part
        const filters_container_styles = [styles.filters];
        if(isMobile) {
            filters_container_styles.push(styles.filters_mobile);
            used_styles.push(styles.blocks_mobile);
        }

        // The blockspage can show all the blocks or only blocks with a specific id.
        // The ids can be given as list or as "all" string to show all the available blocks
        let sel_block_ids;
        if(page_block_ids === "all") {
            sel_block_ids = Object.keys(blocks);
        } else {
            sel_block_ids = page_block_ids
        }

        // create the blocks content
        return (
            <div className={used_styles.join(" ")}>
                <div className={filters_container_styles.join(" ")}>
                    <div className={styles.filter_title}>
                        <TextLabel use_general id="blocks_page_category_title" />
                    </div>
                    <div className={styles.filter_buttons}>
                        {/* Create the "show all" button */}
                        <FilterButton
                            key={'all_options'}
                            onClick={() => this.toggle_selected_category(null)}
                            active={this.state.selected_categories.length === 0}>
                            <TextLabel use_general id="blocks_page_category_show_all" />
                        </FilterButton>
                        {/* Loop through all the categories and create the buttons */}
                        {data_categories.map((item) => {
                            if(check_not_empty(category_ids) === false ||
                                category_ids === "all" || category_ids.includes(item.id)) {
                                return <FilterButton
                                    key={item.id}
                                    onClick={() => this.toggle_selected_category(item.id)}
                                    active={this.state.selected_categories.includes(item.id)}
                                    icon={item.icon}><TextLabel content={item.label} /></FilterButton>
                            }
                            return null;
                        })}
                    </div>
                </div>
                <div className={styles.blocks_container}>
                    {/* Create a grid with the blocks */}
                    <GridContainer
                        filter={this.get_filter_string()}
                        modal_open={modal_is_open}
                    >
                        {sel_block_ids.map((block_id, index) => <BlockFactory
                            blockIndex={index}
                            key={`block_${index}`}
                            block={blocks[block_id]}  />)}
                    </GridContainer>
                </div>
                {/* add the footer text */}
                <div className={styles.footer}>
                    <TextLabel use_general id={"blocks_page_footer"} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        blocks_data_loaded: state.coronaBarometerReducer.blocks_data_loaded,
        blocks: state.coronaBarometerReducer.blocks,
        data_categories: state.coronaBarometerReducer.data_categories,
        modal_is_open: state.timeOverviewModalReducer.is_open
    }
};

export default withRouter(connect(mapStateToProps)(Blocks));

Blocks.propTypes = {
  category_ids: PropTypes.array, // list with the category ids or a string with all
  page_block_ids: PropTypes.any.isRequired, // list with the block ids or a string with all
  sidebar_animation_ready: PropTypes.bool, // bool if the sidebar toggle animation is ready
  sidebar_open: PropTypes.bool, // bool if the sidebar is open or closed
};
