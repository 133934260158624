import React, { Component } from 'react';
import {Route, Switch} from "react-router";
import PageFactory from "../Pages/PageFactory/PageFactory";
import Layout from "../UI/Layout/Layout";
import * as actions from "../../store/actions";
import connect from "react-redux/es/connect/connect";
import TimeOverviewModal from "../Modals/TimeOverviewModal/TimeOverviewModal";
import TimeOverviewMobilePage from "../Pages/TimeOverviewMobilePage/TimeOverviewMobilePage";
import {check_not_empty, isMobile} from "../../utilities";
import {AVAILABLE_LANGUAGES} from "../../Constants/language_constants";


class CoronaBarometer extends Component {
    /*
    * Component which contains the complete corona barometer website.
    * This component use the Layout component to add the layout to the website (header) and add the page routing by
    * using the react router Switch and Route component for every page. The page information is extracted from the
    * json settings file. The url for every page is extracted and extended with the optional language parameters to
    * handle multi language functionality of the website. The PageFactory component is used to get the right page
    * component based on the page type.
    * */
    componentDidMount() {
        /*
        * Initialise the redux store.
        * */
        this.props.corona_barometer_init()
    }

    render() {
        // get the parameters
        const {modal_is_open} = this.props;

        // Check if the website is loaded (redux store is initialised), to prevent data not available errors
        if(!this.props.is_loaded) {
            return null;
        }

        // Create the content of website
        let content;

        // If a model must be showed on a mobile device, show the mobile modal page
        if(modal_is_open && isMobile) {
            content = <TimeOverviewMobilePage />
        } else {
            // Create the language parameter to handle the multi language functionality based on the url prefix.
            // coronabarometer.nl/{language, optional default = nl}/
            const language_parameter = `/:language(${AVAILABLE_LANGUAGES.join("|")})?/`

            // Loop though all the pages present in the page section of the settings file (saved in the redux store)
            // and create for every page a Route which render the PageFactory to create the right page based on the
            // page type
            content = this.props.pages.map((page_data) =><Route
                exact={false}
                path={`${language_parameter}${page_data.path.split("/").filter((key) => check_not_empty(key)).join("/")}`}
                key={page_data.path}
                render={(props) =><PageFactory data={page_data} {...props} />} />)
        }
        // Render the website.
        return (
            <React.Fragment>
                <Layout>
                    <Switch>
                        {content}
                    </Switch>
                </Layout>
                {!isMobile ? <TimeOverviewModal/> : null }
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        pages: state.coronaBarometerReducer.pages,
        general: state.coronaBarometerReducer.general,
        is_loaded: state.coronaBarometerReducer.loaded,
        modal_is_open: state.timeOverviewModalReducer.is_open
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        corona_barometer_init: () => dispatch(actions.corona_barometer_init())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CoronaBarometer);
