import PropTypes from 'prop-types'
import React, { Component } from 'react';
import styles from './BlockPieChart.module.css';
import {
    Tooltip, PieChart, Pie, Cell, ResponsiveContainer,
} from 'recharts';
import {number_to_string, updateObject} from "../../../../utilities";
import {withRouter} from "react-router";
import TextLabel from "../../../SupportedComponents/TextLabel/TextLabel";

// Default graph object settings which can be overridden in the website setup json file
const default_graph_data = {
    width:"100%",
    height: 190,
    block_data: null,
    block_data_keys: null,
    data: [],
    data_key: "value",
    outer_radius: "70%",
    inner_radius: 0,
    name_key: "name",
    label_color: "#353535",
    start_angle: 90,
    end_angle: 360+90,
    unit: ""
};

class BlockPieChart extends Component {
    /* Component to add a pie chart to a statistic block on a blocks page */
    render() {
        const {data, language, ...rest} = this.props;
        // Merge the graph block settings with the settings from the json settings file
        const graph_data = updateObject(default_graph_data, data);

        const renderCustomizedLabel = (props) => {
            // Method to create a custom label in the graph
            const {cy, x, y, name} = props;
            let y_offset = -5;
            if(y > cy) {
                y_offset = y_offset * -2.5;
            }

            return (
                <text x={x} y={y+y_offset} fill="#353535" textAnchor="middle">
                    <TextLabel content={name} />
                </text>
            );
        };

        // render the pie chart
        return (
            <div className={styles.graph_box} {...rest}>
                <ResponsiveContainer
                    width={graph_data.width}
                    height={graph_data.height}
                >
                    <PieChart>
                        <Pie
                            data={graph_data.data}
                            dataKey={graph_data.data_key}
                            outerRadius={graph_data.outer_radius}
                            innerRadius={graph_data.inner_radius}
                            nameKey={graph_data.name_key}
                            labelLine={{stroke: graph_data.label_color}}
                            label={renderCustomizedLabel}
                            startAngle={graph_data.start_angle}
                            endAngle={graph_data.end_angle}
                        >
                            {graph_data.data.map((entry, index) => <Cell key={`cell-${index}`}
                                                                         fill={entry["color"] ? entry["color"] : "#11A8C2"} />)}
                        </Pie>
                        <Tooltip
                            formatter={(value) => number_to_string(value, graph_data.unit, language)}
                            />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

export default withRouter((BlockPieChart));

BlockPieChart.propTypes = {
  data: PropTypes.object.isRequired, // data object of the pie chart
  language: PropTypes.string.isRequired // current selected language
};
