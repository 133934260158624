import PropTypes from 'prop-types'
import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import {check_not_empty} from "../../../utilities";

class Icon extends Component {
    /* Component to render an icon. The icon can be given as path to an image but also as name of the image present
    in the icons folder located in the public folder
    */
    constructor(props) {
        super(props);
        // state used to handle the hover effect
        this.state = {
            hovered: false
        }
    }

    componentDidMount() {
        // init the redux store which saves the icon images from the icons directory
        if(Object.keys(this.props.icons).length === 0) {
            this.props.icons_init()
        }
    }

    set_hover(hovered=true) {
        // method to toggle the hover state
        this.setState({
            hovered: hovered
        })
    };

    is_hovered = () => {
        // check if image is hovered
        return check_not_empty(this.props.is_hovered) ? this.props.is_hovered : this.state.hovered;
    }

    render() {
        const {icon, icon_hover, label, icons_init, icons, is_hovered, ...rest} = this.props;
        // Set the alt text
        let alt_text = label;
        if(label === undefined || label === null) {
            alt_text = ""
        }
        // get the icon path
        let icon_path = icon;
        if(icons.hasOwnProperty(icon)) {
            icon_path = icons[icon];
        }
        let img_props = {src: icon_path};
        // Process the image with hover effect
        if(icon_hover) {
            if(check_not_empty(is_hovered) === false) {
                img_props = {
                    ...img_props,
                    onMouseEnter: () => this.set_hover(true),
                    onMouseLeave: () => this.set_hover(false)
                };
            }
            if(this.is_hovered()) {
                let icon_hover_path = icon_hover;
                if(icons.hasOwnProperty(icon_hover)) {
                    icon_hover_path = icons[icon_hover];
                }
                img_props['src'] = icon_hover_path
            }
        }
        // create image object
        return <img {...img_props} alt={alt_text} {...rest} />
    }
}


const mapStateToProps = (state) => {
    return {
        icons: state.iconsReducer.icons
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        icons_init: () => dispatch(actions.icons_init())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Icon);
export const IconWithoutRedux = Icon; // version without redux store usages

Icon.propTypes = {
    icon: PropTypes.string.isRequired, // path or name of the icon
    icon_hover: PropTypes.string, // path or name of the hovered version of the icon
    is_hovered: PropTypes.bool, // force the hover effect even if the mouse is not on the image itself
    label: PropTypes.any // string, label of the image
}
