import PropTypes from 'prop-types'
import React, {Component} from 'react';
import styles from './BlockPercentage.module.css';

class BlockPercentage extends Component {
    /* Component to add a statistic number or percentage to a statistic block on a blocks page.  */
    render() {
        return (
            <div className={styles.percentage}>{this.props.children}</div>
        )
    }
};

export default BlockPercentage;

BlockPercentage.propTypes = {
  children: PropTypes.any.isRequired // number of percentage to show
};
