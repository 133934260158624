import PropTypes from 'prop-types'
import React, {Component}  from 'react';
import styles from './BlockHeader.module.css';
import Icon, { IconWithoutRedux } from "../../Icon/Icon";
import {check_not_empty, isMobile} from '../../../../utilities';


class BlockHeader extends Component {
    /* Component to add the header to a statistic block on a blocks page. */

    constructor(props) {
        super(props);
        // state used to show / hide the description block in the header
        this.state = {
            show_description: false
        }
    }

    show_description = () => {
        // method to show the description
        if(this.props.description){
            this.setState(() => {
                return {
                    show_description: true
                }
            })
        }
    };

    hide_description = () => {
        // method to hide the description
        if(this.props.description){
            this.setState(() => {
                return {
                    show_description: false
                }
            })
        }
    };

    render() {
        const {icon, icons_list, icons_init, description, doubleWidth, children, ...rest} = this.props;

        // process the styling
        const title_style_classes = [styles.title];
        let text = children;
        const block_styles = [styles.block];

        if(isMobile) {
            block_styles.push(styles.block_mobile)
        }

        if(text === undefined || text === null) {
            text = "";
        }

        if(check_not_empty(description)) {
            title_style_classes.push(styles.title_with_info_icon)
        }

        if((doubleWidth && text.length > 20 && isMobile)) {
            title_style_classes.push(styles.title_long)
        }

        const info_box_style_classes = [styles.info_box];
        if(doubleWidth) {
            if(isMobile) {
                info_box_style_classes.push(styles.info_box_double_width_mobile)
            }
        }

        // Render the header
        return (
            <div className={block_styles.join(" ")} {...rest}>
                {icon ? <div className={styles.icon}>{check_not_empty(icons_list) ? <IconWithoutRedux icon={icon} icons={icons_list} icons_init={icons_init}  /> : <Icon icon={icon} /> }</div> : null }
                {<div className={title_style_classes.join(" ")}>{text}</div>}
                {description ? <div className={styles.info_icon}
                                    onMouseEnter={this.show_description}
                                    onMouseLeave={this.hide_description}>i</div> : null}
                {this.state.show_description ?  <div
                    className={info_box_style_classes.join(" ")}>{description}</div> : null}
            </div>
        )
    }
}


export default BlockHeader;


BlockHeader.propTypes = {
  children: PropTypes.any, // title of the header
  description: PropTypes.any, // description showed in the header
  doubleWidth: PropTypes.bool, // if the block has a double with
  icon: PropTypes.string // icon id or path used in the header
}
