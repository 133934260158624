import PropTypes from 'prop-types'
import React, { Component } from 'react';
import styles from './TextButton.module.css';
import { Link } from 'react-router-dom';
import Icon from "../Icon/Icon";
import {create_url, isMobile} from "../../../utilities";
import {withRouter} from "react-router";
import TextLabel from "../../SupportedComponents/TextLabel/TextLabel";

class TextButton extends Component {
    /* Component to create a text button
    */

    render() {
        const {data, url, children, arrowDirection, linkType, ...rest} = this.props;
        // create the link object
        let link_data = {
            url: url,
            text: children
        }
        if(data !== undefined && data !== null) {
            link_data = {
                ...link_data,
                ...data
            }
        }

        // add arrows if necessary
        let content;
        if(arrowDirection === 'left') {
            content = <React.Fragment>
                <Icon icon="arrow-left-black"/>
                <span><TextLabel content={link_data.text} /></span>
            </React.Fragment>;
        } else {
            content = <React.Fragment>
                <span><TextLabel content={link_data.text} /></span>
                <Icon icon="arrow-right-black"/>
            </React.Fragment> ;
        }

        // style the button
        const link_style_classes = [styles.button];
        if(linkType === undefined || linkType === null || linkType === "" || linkType === 'link') {
            link_style_classes.push(styles.link);
        } else if(linkType === 'buttonGray') {
            link_style_classes.push(styles.button_gray);
            if(isMobile === false) {
                link_style_classes.push(styles.button_gray_desktop);
            }
        }

        // create normal html link if a url is given
        if(link_data.url.startsWith("http")) {
            return <a href={link_data.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={link_style_classes.join(" ")}
                      {...rest}>{content}</a>
        }

        // create a react router link button to go to a internal page
        return <Link to={create_url(link_data.url, this.props)} className={link_style_classes.join(" ")} {...rest}>{ content }</Link>
    }
}

export default withRouter(TextButton);

TextButton.propTypes = {
  arrowDirection: PropTypes.string, // left or right to add a arrow to the button
  children: PropTypes.string, // text of the button
  data: PropTypes.object, // the data object of the link
  linkType: PropTypes.string, // type of the link: link or buttonGray
  url: PropTypes.string // external url of path to the internal page
};
