import PropTypes from 'prop-types'
import React, { Component } from 'react';
import styles from './MessageBox.module.css';


class MessageBox extends Component {
    /* Component to show a message box, used to show warnings
    * */
    render() {
        const {show, children} = this.props;
        if(show) {
            return <div className={styles.message_box}>{children}</div>
        }
        return null;
    }
}


export default MessageBox;


MessageBox.propTypes = {
  children: PropTypes.string, // content of the message box
  show: PropTypes.bool // show the messagebox
};
