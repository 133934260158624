import PropTypes from 'prop-types'
import React, { Component } from 'react';
import styles from './TextPage.module.css';
import TextButton from "../../UI/TextButton/TextButton";
import GridItem from "../../Grid/GridItem/GridItem";
import Socials from "../../UI/Socials/Socials";
import BasicGridContainer from "../../Grid/BasicGridContainer/BasicGridContainer";
import {get_language_from_props, get_text, updateObject} from "../../../utilities";
import {withRouter} from "react-router";
import TextLabel from "../../SupportedComponents/TextLabel/TextLabel";

// Default page object settings which can be overridden in the website setup json file
const default_page_data = {
    title: null,
    content: null,
    links: [],
    logos: [],
    logos_title: null,
};

class TextPage extends Component {
    /* Component to show a Text page
    * */

    render() {
        const { data } = this.props;
        // Merge the default block settings with the settings from the json settings file
        const page_data = updateObject(default_page_data, data);
        // get the language from the props
        const language = get_language_from_props(this.props);
        // Render the page
        return (
            <div className={styles.page}>
                <h1><TextLabel content={page_data.title} process_html /></h1>
                <div className={styles.content_text_block}>
                    <TextLabel content={page_data.content} process_html />
                </div>
                {/* add logos to the page by using a basic grid and the logos as grid items */}
                {page_data.logos.length > 0 ? <div className={styles.logos}>
                    {page_data.logos_title ? <span className={styles.logos_title}>
                        <TextLabel content={page_data.logos_title} process_html />
                    </span> : null }
                    <BasicGridContainer>
                        {page_data.logos.map((logo_data, index) => <GridItem key={`logo_${index}`}
                                                                             disableMobileScale>
                            <div className={styles.single_logo}>
                                {logo_data.url ? <a href={logo_data.url} target="_blank" rel="noopener noreferrer">
                                        <img src={logo_data.image_path} alt={get_text(logo_data.label, null, null, language)} />
                                    </a> : <img src={logo_data.image_path} alt={get_text(logo_data.label, null, null, language)} />}
                            </div>
                        </GridItem>)}
                    </BasicGridContainer>
                </div> : null }
                {/* Add the links in the footer of the page together with the socials */}
                <div className={styles.footer}>
                    {page_data.links.map((link_data, index) => <TextButton key={`link_${index}`}
                                                                           data={link_data}
                                                                           arrowDirection={"left"}
                                                                           linkType={"buttonGray"} />)}
                    <Socials className={styles.footer_socials} />
                </div>
            </div>
        )
    }
}

export default withRouter(TextPage);

TextPage.propTypes = {
  data: PropTypes.object.isRequired // the data of the text page from the settings file
}
