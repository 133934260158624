import PropTypes from 'prop-types'
import React, {Component} from 'react';
import styles from './Layout.module.css';
import Header from "../Header/Header";
import {isMobile} from '../../../utilities';
import TextLabel from "../../SupportedComponents/TextLabel/TextLabel";

class Layout extends Component{
    /* Component to show the layout of the website which contains the header and body of the website
    *
    * */
    render() {
        const {children} = this.props;

        // handle styling and background
        let backgroundImage = null;
        const inner_content_style_classes = [styles.inner_content];
        if(isMobile) {
            inner_content_style_classes.push(styles.inner_content_mobile);
        } else {
            backgroundImage = require('./background.png');
        }
        // render layout. The body of the website is given by the children parameter
        return (
            <div className={styles.page}>
                <Header
                    title={<TextLabel use_general id={["header", "title"]} process_html />}
                />
                <div className={isMobile ? styles.content_mobile : styles.content}
                     style={ isMobile ? null : {background: `url(${backgroundImage}) no-repeat center center fixed`}}>
                    <div className={inner_content_style_classes.join(" ")}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}


export default Layout;


Layout.propTypes = {
  children: PropTypes.any, // Body of the website
};
