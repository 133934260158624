import PropTypes from 'prop-types'
import React, { Component } from 'react';
import styles from "./Selector.module.css";
import SelectorOption from "./SelectorOption/SelectorOption";
import Icon from "../../Icon/Icon";
import {
    check_not_empty, current_page_info,
    get_filter_key,
    get_latest_data_week_key,
    has_filter,
    isMobile
} from "../../../../utilities";
import connect from "react-redux/es/connect/connect";
import FilterButton from "../../FilterButton/FilterButton";
import * as actions from "../../../../store/actions";
import {
    GRAPH_SELECTION_TYPE_FILTER,
    GRAPH_SELECTION_TYPE_MEAN,
    GRAPH_SELECTION_TYPE_VARIABLE
} from "../../../../Constants/graph_selection_type_constants";
import FilterGroupButtons from "../../FilterGroupButtons/FilterGroupButtons";
import {withRouter} from "react-router";
import TextLabel from "../../../SupportedComponents/TextLabel/TextLabel";

class Selector extends Component {
    /* Component to create the comparison options in the modal or mobile page
    * */
    selection_part_variable = () => {
        // Method to create the comparison selector of another variable
        const {blocks, active_data_filters, blocks_data, week_ids, block_filters,
            main_block_id, selected_block_id, location, pages} = this.props;
        // get the current webpage information to show only the comparison variables which are showed on the webpage
        const cur_web_page = current_page_info(location, pages);
        let blocks_filter_ids = null;
        if(check_not_empty(cur_web_page) &&
            check_not_empty(cur_web_page.block_ids) &&
            cur_web_page.block_ids !== "all") {
            blocks_filter_ids = cur_web_page.block_ids;
        }

        // get the active filter key
        const active_filter_key = get_filter_key(active_data_filters, block_filters);

        // create the selector options of the different available variables
        return <div className={styles.filter_section}>
            <span className={styles.title}>
                <TextLabel use_general id="select_variable" />
            </span>
            <ul className={styles.filters_options}>
                {Object.keys(blocks).map((block_id) => {
                    if(blocks_filter_ids === null || blocks_filter_ids.includes(block_id)) {
                        const block = blocks[block_id];
                        if(block.type === "number") {
                            const sel_data = blocks_data[active_filter_key][block_id];
                            if (check_not_empty(sel_data)) {
                                const data_key = get_latest_data_week_key(sel_data, week_ids);
                                if (data_key !== null) {
                                    return <SelectorOption
                                        key={block.id}
                                        onClick={() => this.change_selector_block_id(block.id)}
                                        selected={selected_block_id === block.id && main_block_id !== block.id}
                                        active={main_block_id === block.id}
                                    >
                                        <TextLabel content={block.title} />
                                    </SelectorOption>
                                }
                            }
                        }
                    }
                    return null;
                })}
            </ul>
            </div>;
    };

    selection_part_custom_filter = () => {
        // create the custom filter option selection based on the selector buttons
        return <div>
            {this.props.block_filters.map((filter) => <FilterGroupButtons
                key={filter.id}
                filter={filter}
                filters={this.props.block_filters}
                active_filter={this.props.selected_data_filters}
                onChange={this.change_selected_filter}
                small_buttons
            />)}
        </div>
    };

    change_selector = (selection_type) => {
        // method to change which comparison type must be used
        if(isMobile) {
            if(selection_type === GRAPH_SELECTION_TYPE_MEAN) {
                if(this.props.filterCloseHandler !== undefined) {
                    this.props.filterCloseHandler();
                }
            }
        }
        this.props.set_selection_type(selection_type);
    };

    change_selector_block_id = (new_id) => {
        // method to change which variable must be used in the variable comparison
        this.props.set_selected_block_id(new_id);
        if(this.props.filterCloseHandler !== undefined) {
            this.props.filterCloseHandler();
        }
        if(this.props.selection_type !== GRAPH_SELECTION_TYPE_VARIABLE) {
            this.change_selector(GRAPH_SELECTION_TYPE_VARIABLE);
        }
    };

    change_selected_filter = (group, filter_id) => {
        // method to change the filter type
        this.props.set_selected_data_filters({
            ...this.props.selected_data_filters,
            [group]: filter_id
        });
        const updated_selected_filter = {
            ...this.props.selected_data_filters,
            [group]: filter_id
        };
        if(has_filter(updated_selected_filter, true)) {
            if(this.props.filterCloseHandler !== undefined) {
                this.props.filterCloseHandler();
            }
        }
    };

    render() {
        const {filterCloseHandler, selection_type, set_selection_type, active_data_filters,
            children, hide_variable_filter, location, pages, ...rest} = this.props;

        // process the styling
        const block_style_classes = [styles.selector_block];
        const selector_content_styles = [styles.selector_content];
        if(isMobile) {
            block_style_classes.push(styles.selector_block_mobile);
            selector_content_styles.push(styles.selector_content_mobile);
        }

        // return the selector
        return (
            <div className={block_style_classes.join(" ")} {...rest}>
                {isMobile ? <div className={styles.selector_title}>
                    <TextLabel use_general id="time_overview_selector_title" />
                    <Icon icon={'cross-white'}
                          icon_hover={'cross-white'}
                          onClick={filterCloseHandler}
                          className={styles.filter_close_handler}
                    />
                </div> : null}
                <div className={selector_content_styles.join(" ")}>
                    <div className={styles.selection_type_buttons}>
                        <span className={styles.title}>
                            <TextLabel use_general id="compare_with" />
                        </span>
                        <FilterButton
                            onClick={() => this.change_selector(GRAPH_SELECTION_TYPE_MEAN)}
                            active={selection_type === GRAPH_SELECTION_TYPE_MEAN}
                            disabled={has_filter(active_data_filters) === false}
                            small>
                            <TextLabel use_general id="compare_mean" />
                        </FilterButton>
                        {hide_variable_filter === false ? <FilterButton
                            onClick={() => this.change_selector(GRAPH_SELECTION_TYPE_VARIABLE)}
                            active={selection_type === GRAPH_SELECTION_TYPE_VARIABLE}
                            small>
                            <TextLabel use_general id="compare_other_variable" />
                        </FilterButton> : null}
                        <FilterButton
                            onClick={() => this.change_selector(GRAPH_SELECTION_TYPE_FILTER)}
                            active={selection_type === GRAPH_SELECTION_TYPE_FILTER}
                            small>
                            <TextLabel use_general id="compare_other_filter" />
                        </FilterButton>
                    </div>
                    <div className={styles.other_filter}>
                        {selection_type === GRAPH_SELECTION_TYPE_FILTER ? this.selection_part_custom_filter() : null}
                        {selection_type === GRAPH_SELECTION_TYPE_VARIABLE ? this.selection_part_variable() : null}
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        blocks: state.coronaBarometerReducer.blocks,
        blocks_data: state.coronaBarometerReducer.blocks_data,
        main_block_id: state.timeOverviewModalReducer.main_block_id,
        selected_block_id: state.timeOverviewModalReducer.selected_block_id,
        selected_block_data_filters: state.timeOverviewModalReducer.selected_block_data_filters,
        selection_type: state.timeOverviewModalReducer.selection_type,
        pages: state.coronaBarometerReducer.pages,
        data_filters: state.coronaBarometerReducer.data_filters,
        week_ids: state.coronaBarometerReducer.week_ids,
        block_filters: state.coronaBarometerReducer.block_filters,
        active_data_filters: state.coronaBarometerReducer.active_data_filters,
        selected_data_filters: state.coronaBarometerReducer.selected_data_filters,
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        set_selected_data_filters: (active_data_filters) => dispatch(actions.corona_barometer_set_selected_data_filters(active_data_filters)),
        set_selection_type: (selection_type) => dispatch(actions.time_overview_modal_set_selection_type(selection_type)),
        set_selected_block_id: (selected_block_id) => dispatch(actions.time_overview_modal_set_selected_block_id(selected_block_id))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Selector));


Selector.propTypes = {
  hide_variable_filter: PropTypes.bool // if the variable comparison is available
};
