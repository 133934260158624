import React, {Component} from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import styles from './TimeOverviewModal.module.css'
import TimeOverviewHeader from "../../UI/TimeOverviewElements/TimeOverviewHeader/TimeOverviewHeader";
import * as actions from '../../../store/actions';
import LineGraph from "../../UI/TimeOverviewElements/LineGraph/LineGraph";
import Selector from "../../UI/TimeOverviewElements/Selector/Selector";
import {check_not_empty, check_text, get_language_from_url, get_text} from "../../../utilities";
import ComparisonBarGraph from "../../UI/TimeOverviewElements/ComparisonBarGraph/ComparisonBarGraph";
import TextLabel from "../../SupportedComponents/TextLabel/TextLabel";
import {withRouter} from "react-router";

class TimeOverviewModal extends Component {
    /* Component to show the modal with the variable/time overview
    * graph and to compare different variables of filter results. The modal is not showed on a mobile device, but the
    * TimeOverviewMobilePage is used to add the same functionality to the mobile version of the website.
    * The react-modal JS Component plugin is used to create the modal.
    * Website: http://reactcommunity.org/react-modal/
    * */
    componentDidMount() {
        // Connect the modal to the website body
        Modal.setAppElement('body');
    }

    closeModalHandler = () => {
        // To prevent scrolling of the website when the showed, the body overview style will set to hidden.
        // This style parameter must be removed when the model is closed to reactivate scrolling
        document.body.removeAttribute('style');
        this.props.close_modal_handler();
    };

    render() {
        const {main_block_id, modal_is_open, blocks, general} = this.props;
        // Get the modal title
        const language = get_language_from_url(this.props);
        const modal_title = get_text(general, "time_overview_selector_title", "", language);

        // Show only the modal if the statistic id (id of the statistic block) is set
        if(blocks.hasOwnProperty(main_block_id)) {
            // get the block data
            const main_block = blocks[main_block_id];

            // Get the description. Use the modal_sub_text if present, otherwise use the more
            // general number_sub_text variable of the block
            const header_description = check_text(main_block.modal_sub_text, main_block.number_sub_text);

            // Create the modal object, and the content which contains the Modal header (TimeOverviewHeader),
            // the graph dependent on the variable title and the comparison variable selector (Selector component)
            return (
                <div>
                    <Modal
                        isOpen={modal_is_open}
                        contentLabel={modal_title}
                        className={styles.Modal}
                        overlayClassName={styles.Overlay}
                        onRequestClose={this.closeModalHandler}
                        onAfterOpen={() => document.body.style.overflow = 'hidden'}
                    >
                        <TimeOverviewHeader
                            modalCloseHandler={this.closeModalHandler}
                            icon={main_block.icon}
                            title={<TextLabel content={main_block.title} />}
                            description={check_not_empty(header_description) ? <TextLabel content={header_description} process_html /> : null}
                            info_description={check_not_empty(main_block.description) ? <TextLabel content={main_block.description} process_html /> : null}
                        />
                        {main_block.type === "bar_graph" ? <ComparisonBarGraph/> : null}
                        {main_block.type === "number" ? <LineGraph /> : null}

                        <Selector
                            hide_variable_filter={main_block.type === "bar_graph"}
                        />
                    </Modal>
                </div>
            )
        }
        return null;
    }
}


const mapStateToProps = (state) => {
    return {
        blocks: state.coronaBarometerReducer.blocks,
        modal_is_open: state.timeOverviewModalReducer.is_open,
        main_block_id: state.timeOverviewModalReducer.main_block_id,
        general: state.coronaBarometerReducer.general
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        close_modal_handler: () => dispatch(actions.time_overview_modal_set_modal_close())
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeOverviewModal));
