import React, { Component } from 'react';
import styles from './TimeOverviewMobilePage.module.css';
import * as actions from "../../../store/actions";
import {connect} from "react-redux"
import TimeOverviewHeader from "../../UI/TimeOverviewElements/TimeOverviewHeader/TimeOverviewHeader";
import LineGraph from "../../UI/TimeOverviewElements/LineGraph/LineGraph";
import Icon from "../../UI/Icon/Icon";
import {Redirect, withRouter} from "react-router";
import Selector from "../../UI/TimeOverviewElements/Selector/Selector";
import {check_text} from "../../../utilities";
import ComparisonBarGraph from "../../UI/TimeOverviewElements/ComparisonBarGraph/ComparisonBarGraph";
import TextLabel from "../../SupportedComponents/TextLabel/TextLabel";

class TimeOverviewMobilePage extends Component {
    /* Component to show a page with the comparison graph on a mobile device instead of a modal
    */
    constructor(props) {
        super(props);

        // state to handle if the comparison menu is open or closed
        this.state = {
            is_filter_open: false,
        }
    }

    toggle_selector = () => {
        // Method to toggle the comparison menu
        this.setState((old_state) => {
            return {
                is_filter_open: !old_state.is_filter_open
            }
        });
    };

    back_to_data_handler = () => {
        // method to close the comparison page and go back to the blocks page
        this.props.close_modal_handler();
    };

    render() {
        const {blocks, main_block_id} = this.props;
        // Show only the  page if the statistic id (id of the statistic block) is set
        if(blocks.hasOwnProperty(main_block_id)) {
            // get the block data
            const main_block = blocks[main_block_id];

            // Handle the style of the toggle
            const filter_block_style_classes = [styles.filter_block];
            if(this.state.is_filter_open) {
                filter_block_style_classes.push(styles.open);
            }
            else {
                filter_block_style_classes.push(styles.close);
            }

            // create the content
            return (
                <div className={styles.page}>
                    <div className={filter_block_style_classes.join(" ")}>
                        <Selector
                            filterCloseHandler={this.toggle_selector}
                            hide_variable_filter={main_block.type === "bar_graph"}
                        />
                    </div>
                    <TimeOverviewHeader
                        modalCloseHandler={this.closeModalHandler}
                        icon={main_block.icon}
                        title={<TextLabel content={main_block.title} />}
                        description={<TextLabel content={check_text(main_block.modal_sub_text, main_block.number_sub_text)} process_html />}
                        info_description={<TextLabel content={main_block.description} process_html />}
                        compareButtonHandler={this.toggle_selector}
                        compareButtonText={<TextLabel use_general id="time_overview_selector_title_short" />}
                    />
                    {main_block.type === "bar_graph" ? <ComparisonBarGraph/> : null}
                    {main_block.type === "number" ? <LineGraph /> : null}
                    <div className={styles.buttons}>
                        <div className={styles.button_back}
                             onClick={this.back_to_data_handler}
                        >
                            <Icon icon="arrow-left-black"/>
                            <span>
                                <TextLabel use_general id="button_back_to_data" />
                            </span>
                        </div>
                    </div>
                </div>
            )
        }
        return <Redirect to={"/"} />;
    }
}

const mapStateToProps = (state) => {
    return {
        blocks: state.coronaBarometerReducer.blocks,
        blocks_data: state.coronaBarometerReducer.blocks_data,
        modal_is_open: state.timeOverviewModalReducer.is_open,
        main_block_id: state.timeOverviewModalReducer.main_block_id,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        close_modal_handler: () => dispatch(actions.time_overview_modal_set_modal_close())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeOverviewMobilePage));
