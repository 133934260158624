import PropTypes from 'prop-types'
import React, {Component} from 'react';
import styles from './BlockDescription.module.css';
import { isMobile } from '../../../../utilities';

class BlockDescription extends Component {
    /* Component to add a description to a statistic block on a blocks page. Used for te text below a percentage */
    render() {
        const {children, className, ...rest} = this.props;
        // do not render the component if no text is present
        if(children === undefined || children === null || children === "") {
            return null;
        }
        // Handle styling
        const block_styles = [styles.description];
        if(isMobile) {
            block_styles.push(styles.description_mobile);
        }
        block_styles.push(className);

        // Render the block
        return (
            <div className={block_styles.join(" ")} {...rest}>{children}</div>
        )
    }
}

export default BlockDescription;

BlockDescription.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string
}
