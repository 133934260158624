import firebase from 'firebase/app'
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export const firestore_get_blocks_by_filter_key = (filter_key) => {
    return db.collection("blocks_data").doc(filter_key).get();
};

export const firestore_get_all_blocks = () => {
    return db.collection("blocks_data").get();
};

