import PropTypes from 'prop-types'
import React, {Component} from 'react';
import styles from './GridItem.module.css';
import {isMobile} from '../../../utilities';

class GridItem extends Component {
    /* This class implements the grid item which is used in the grid layout as single block. The blocks are automatically arranged by the Isotope JS plugin
    * https://isotope.metafizzy.co/
    * This component must be used together with the grid container which wraps all the single GridItems.
    * The plugin implements also the category filters which is implemented by the html classes which can be given to
    * this GridItem className parameter. Multiple categories can be given as multiple classNames in one string.
    * */

    render() {
        const {doubleWidth, disableMobileScale, className} = this.props;
        // Create the container class
        const container_classes = ["grid-item", styles.block];
        // Blocks are different scale on mobile devices, but this can be disabled by using the disableMobileScale
        // parameter. If it is not disabled, add the right classes to the class list
        if(disableMobileScale === undefined || disableMobileScale === false) {
            if(isMobile) {
                if(doubleWidth) {
                    container_classes.push(styles.double_block_mobile);
                } else {
                    container_classes.push(styles.single_block_mobile);
                }
            }
        }
        // Add the custom classes (also used for filtering
        container_classes.push(className);

        // Render the gridItem
        return (
            <div className={container_classes.join(" ") }>
                {this.props.children}
            </div>
        )
    }
}

export default GridItem

GridItem.propTypes = {
    children: PropTypes.any, // Content of the block
    className: PropTypes.string, // classnames of the GridItem block, also used for filter categories
    disableMobileScale: PropTypes.bool, // Disable the mobile scaling of the grid items
    doubleWidth: PropTypes.bool, // True if the block has the width of two single widths
};
