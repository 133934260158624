import PropTypes from 'prop-types'
import React, {Component} from 'react';
import styles from './TextBlock.module.css';
import BaseBlock from "../BaseBlock/BaseBlock";
import BlockHeader from "../../UI/BlockElements/BlockHeader/BlockHeader";
import BlockDescription from "../../UI/BlockElements/BlockDescription/BlockDescription";
import {withRouter} from "react-router";
import {check_not_empty, get_block_categories, isMobile, updateObject} from "../../../utilities";
import Ink from "react-ink";
import TextLabel from "../../SupportedComponents/TextLabel/TextLabel";
// Default block object settings which can be overridden in the website setup json file
const default_block_data = {
    title: null,
    description: null,
    category: null,
    icon: null,
    links: [],
};

class TextBlock extends Component {
    /*
    * Component to create a text block. Component will be used if the block type is set to "text".
    * */
    handle_onclick = () => {
        // Method to handle a click on the block
        setTimeout(() => {
            this.props.history.push(this.props.block.onclick_internal_path);
        }, 150);
    };

    render() {
        const {block} = this.props;
        // Merge the default block settings with the settings from the json settings file
        const block_merged = updateObject(default_block_data, block);

        // Check if the block is clickable
        const has_onclick = check_not_empty(this.props.block.onclick_internal_path);

        // Return the block content with the basic block wrapper
        return (
            <BaseBlock
                gridItemClassName={get_block_categories(block_merged)}
                className={styles.block}
                onClick={has_onclick ? this.handle_onclick : null}
            >
                <BlockHeader icon={block_merged.icon}>
                    <TextLabel content={block_merged.title} process_html />
                </BlockHeader>
                <BlockDescription className={styles.description}>
                    <TextLabel content={block_merged.description}
                               process_html />
                </BlockDescription>
                {/* Add click effect if block is clickable */}
                {has_onclick ? <Ink
                    style={{
                        borderRadius: "12px",
                        left: "10px",
                        top: "10px",
                        width: "calc(100% - 20px)",
                        height: "calc(100% - 20px)",
                    }}
                    ref={this.inkRef}
                    duration={500}
                    opacity={0.25}
                    radius={1500}
                    hasTouch={isMobile}
                /> : null}
            </BaseBlock>
        )
    }
}

export default withRouter(TextBlock);


TextBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired, // number of the block on the blocks page
    block: PropTypes.object.isRequired, // Object of the block from the json setup file
};
