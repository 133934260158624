import React, { Component } from 'react';
import styles from './Socials.module.css';
import connect from "react-redux/es/connect/connect";
import Icon from "../Icon/Icon";
import TextLabel from "../../SupportedComponents/TextLabel/TextLabel";
import {check_not_empty} from "../../../utilities";

class Socials extends Component {
    /* Component to create the socials container */
    render() {
        const {general, className, ...rest} = this.props;
        // get the socials from the json settings file
        const socials_data = general.socials;
        if(socials_data === undefined || socials_data.icons === undefined || socials_data.icons.length === 0) {
            return null
        }
        // create social buttons
        const div_styles = [styles.socials, className];
        return (
            <div className={div_styles.join(" ")} {...rest}>
                {check_not_empty(socials_data.title) ? <span><TextLabel content={socials_data.title} /></span> : null}
                {socials_data.icons.map((social, index) => <a key={`social_${index}`}
                                                              href={social.url}
                                                              target="_blank"
                                                              rel="noopener noreferrer">
                    <Icon icon={social.icon} icon_hover={social.icon_hover}/></a>)}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        general: state.coronaBarometerReducer.general,
    }
};


export default connect(mapStateToProps, () => {return {}})(Socials);
