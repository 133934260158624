import PropTypes from 'prop-types'
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Isotope from 'isotope-layout';

// Container for isotope grid
class GridContainer extends Component {
    /*
    * The blocks layout is arranged and filtered by using the Isotope js package.
    * https://isotope.metafizzy.co/
    * The packages uses two basic blocks, the grid container (this component) and all the individual blocks (items)
    * which is implemented by the GritItem component. The plugin arrange the blocks automatically based on screen width.
    * The filtering of the categories of the blocks are also performed by the plugin by using the html classes of
    * the grid-items to known the category and the filter option of the isotope.arrange method.
    * Blocks with the hide_block class will always be hided (filtered out).
    *
    * Isotope grid is implemented in this object (GridContainer) and BasicGridContainer,
    * which contains the basic functionality without the filter option.
    *
    * */

    constructor(props) {
        super(props);
        // Use a reference to the isotope object
        this.state = {
            node: null,
            isotope: null,
        };
    }

    render() {
        // Render the Isotype grid container with the grid items, given as child items
        return(
            <div className="grid" style={{position: "relative"}}>
                {this.props.children}
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // do not rearrange the blocks when the modal is open
        return nextProps.modal_open === false;
    }


    componentDidMount() {
        // set up isotope
        const node = ReactDOM.findDOMNode(this);
        if (!this.state.isotope) {
            // create isotope object
            this.setState({
                node: node,
                isotope: new Isotope(node, {
                    layoutMode: 'masonry',
                    percentPosition: true,
                    hiddenStyle: {
                        opacity: 0
                    },
                    visibleStyle: {
                        opacity: 1
                    }
                } )
            });
        } else {
            // Reload the items if the object is earlier created
            this.state.isotope.reloadItems();
        }
    }

    componentDidUpdate() {
        // Update the isotope object to enable filtering
        if (this.state.isotope) {
            setTimeout(() => {
                this.state.isotope.arrange({
                    filter: `${this.props.filter}:not(.hide_block)`,
                });
            }, 400);
            this.state.isotope.layout();
        }
    }
}

export default GridContainer;

GridContainer.propTypes = {
    children: PropTypes.any, // The GridItems
    modal_open: PropTypes.bool, // If a model is showed
    filter: PropTypes.string.isRequired, // The category filter string

};
