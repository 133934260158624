import React, {Component} from 'react';
import SingleStatsBlock from "../SingleStatsBlock/SingleStatsBlock";
import TextBlock from "../TextBlock/TextBlock";
import BarGraphBlock from "../BarGraphBlock/BarGraphBlock";
import PieChartBlock from "../PieChartBlock/PieChartBlock";
import GroupBlock from "../GroupBlock/GroupBlock";
import {check_not_empty} from "../../../utilities";
import PropTypes from "prop-types";
import BaseBlock from "../BaseBlock/BaseBlock";

class BlockFactory extends Component {
    /*
    * Component which render the correct block based on the block type (factory pattern)
    * The type parameter of the blocks are used to determine which block component must be rendered.
    * The block settings are added to the block in the block parameter
    *
    * */
    render() {
        const {block, blockIndex, in_group_block} = this.props;
        let in_group_block_internal = in_group_block
        if(check_not_empty(in_group_block) === false) {
            in_group_block_internal = false;
        }

        // Return null if block type is not set
        if(block.type === undefined || block.type === null) {
            return null;
        }

        // render the right block
        switch (block.type) {
            case block_types.NUMBER:
                return <SingleStatsBlock block={block} blockIndex={blockIndex} in_group_block={in_group_block_internal}/>;
            case block_types.BAR_CHART:
                return <BarGraphBlock block={block} blockIndex={blockIndex} in_group_block={in_group_block_internal}/>;
            case block_types.PIE_CHART:
                return <PieChartBlock block={block} blockIndex={blockIndex} in_group_block={in_group_block_internal}/>;
            case block_types.TEXT:
                return <TextBlock block={block} blockIndex={blockIndex} in_group_block={in_group_block_internal}/>;
            case block_types.GROUP:
                return <GroupBlock block={block} blockIndex={blockIndex} in_group_block={in_group_block_internal}/>;
            default:
                return null
        }
    }
}

export default BlockFactory;

BaseBlock.propTypes = {
    block: PropTypes.object, // Object of the block from the json setup file
    blockIndex: PropTypes.number, // number of the block on the blocks page
    in_group_block: PropTypes.bool, // Bool, if block is in a group block
};


// Available block types as constants
export const block_types = {
    NUMBER: "number",
    BAR_CHART: "bar_graph",
    PIE_CHART: "pie_chart",
    TEXT: "text",
    GROUP: "group"
};
