import * as actionTypes from './../actions/actionTypes';
import {updateObject} from "../../utilities";
import {GRAPH_SELECTION_TYPE_MEAN} from "../../Constants/graph_selection_type_constants";

const initState = {
    is_open: false,
    main_block_id: "",
    selection_type: GRAPH_SELECTION_TYPE_MEAN,
    selected_block_id: "",
};

const coronaBaroneterReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.TIME_OVERVIEW_MODEL_SET_IS_OPEN: return updateObject(state, {is_open: action.is_open});
        case actionTypes.TIME_OVERVIEW_MODEL_SET_MAIN_BLOCK_ID: return updateObject(state, {main_block_id: action.main_block_id});
        case actionTypes.TIME_OVERVIEW_MODEL_SET_SELECTION_TYPE: return updateObject(state, {selection_type: action.selection_type});
        case actionTypes.TIME_OVERVIEW_MODEL_SET_SELECTED_BLOCK_ID: return updateObject(state, {selected_block_id: action.selected_block_id});
        default: return state
    }
};

export default coronaBaroneterReducer;
