import React, { Component } from 'react';
import styles from './LineGraph.module.css';
import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {
    check_na_value,
    check_not_empty,
    check_text,
    get_filter_key, get_filter_string, get_language_from_url,
    get_text,
    get_week_number_from_key,
    has_filter, is_same_filter,
    isMobile,
    number_to_string,
    round_number,
    study_week_nr_to_string
} from "../../../../utilities";
import connect from "react-redux/es/connect/connect";
import {
    GRAPH_SELECTION_TYPE_FILTER,
    GRAPH_SELECTION_TYPE_MEAN,
    GRAPH_SELECTION_TYPE_VARIABLE
} from "../../../../Constants/graph_selection_type_constants";
import Loader from "../../Loader/Loader";
import MessageBox from "../../MessageBox/MessageBox";
import TextLabel from "../../../SupportedComponents/TextLabel/TextLabel";
import {withRouter} from "react-router";

class LineGraph extends Component {
    /* Component to show a comparison line graph in the modal or mobile page to compare multiple filters or
    variables with each other
    * */
    render() {
        const {main_block_id, selected_block_id, selected_data_filters, blocks, blocks_data,
            week_ids, active_data_filters, default_filter_id, general, selection_type,
            block_filters, blocks_data_loaded} = this.props;
        // Get current selected language from the url
        const language = get_language_from_url(this.props);
        // Get the filter key of the active filters
        const filter_key = get_filter_key(active_data_filters, block_filters);
        // get the block of the main data
        const main_block = blocks[main_block_id];
        // get the line name of the main data line
        const main_block_title = get_text(main_block.title, null, null, language);

        // find the data of the line which will be showed as comparison line, can be the average, another variable of
        // data from another group, e.q. custom selected filter
        let compare_block_data = null;
        let compare_block = null;
        let compare_title = "";
        let is_loading = false;
        let show_not_available_message = false;
        if(selection_type !== null) {
            if(selection_type === GRAPH_SELECTION_TYPE_MEAN && has_filter(active_data_filters)) {
                // show the average data

                // get the average data
                compare_block_data = blocks_data[default_filter_id][main_block_id];
                compare_block = blocks[main_block_id];
                // Get the graph bar title
                compare_title = get_text(general, "compare_mean", "", language);
            } else if(selection_type === GRAPH_SELECTION_TYPE_VARIABLE && main_block_id !== selected_block_id) {
                // show data from another variable
                if(check_not_empty(selected_block_id)) {
                    if(blocks_data[filter_key].hasOwnProperty(selected_block_id)) {
                        // get the data from the other variable
                        compare_block_data = blocks_data[filter_key][selected_block_id];
                        compare_block = blocks[selected_block_id];
                        // get the title of the other variable
                        if(check_not_empty(compare_block) && compare_block_data !== undefined) {
                            compare_title = get_text(compare_block.title, null, null, language);
                        }
                    } else {
                        show_not_available_message = true;
                    }
                }
            } else if(selection_type === GRAPH_SELECTION_TYPE_FILTER && has_filter(selected_data_filters) &&
                is_same_filter(active_data_filters, selected_data_filters) === false) {
                // compare with another group (custom comparison filter)
                if(blocks_data_loaded) {
                    // Get the other filter key
                    const selected_data_key = get_filter_key(selected_data_filters, block_filters);
                    if(blocks_data[selected_data_key].hasOwnProperty(main_block_id)) {
                        // get the comparison data
                        compare_block_data = blocks_data[selected_data_key][main_block_id];
                        compare_block = blocks[main_block_id];
                        // get the compare title
                        compare_title = get_filter_string(selected_data_filters, block_filters, language);
                    } else {
                        show_not_available_message = true;
                    }
                } else {
                    is_loading = true;
                }
            }
        }
        // process the data that it can be used with the graph plugin
        const graph_data = week_ids.map(week_id => {
            const row = {
                "week_nr": get_week_number_from_key(week_id),
                [main_block_title]: check_na_value(blocks_data[filter_key][main_block.id][week_id])
            };
            if(check_not_empty(compare_block_data)) {
                row[compare_title] = check_na_value(compare_block_data[week_id])
            }
            return row;
        });

        // check if the main data and comparison data of the same type, or that an second yas is necessary
        let has_second_yas = false;
        let active_data_unit= "";
        let selected_data_unit = "";
        if(check_not_empty(main_block)) {
            active_data_unit = check_text(main_block.unit, "");
        }
        if(check_not_empty(compare_block)) {
            selected_data_unit = check_text(compare_block.unit, "");
        }
        if(active_data_unit !== selected_data_unit && check_not_empty(compare_block)) {
            has_second_yas = true
        }
        const block_style_classes = [styles.graph_block];

        // set the height of the graph
        let height = 400;
        if(isMobile) {
            block_style_classes.push(styles.graph_block_mobile);
            height = 300;
        }
        // create the graph
        return (
            <div className={block_style_classes.join(" ")}>
                <MessageBox show={show_not_available_message}>
                    <TextLabel use_general id="filter_result_no_data" />
                </MessageBox>
                {is_loading ? <Loader margin_top={200} /> : <ResponsiveContainer
                    width={"100%"}
                    height={height}
                >
                    {/* Create the line chart*/}
                    <LineChart
                        data={graph_data}
                        margin={{
                            top: 10, right: 30, left: 5, bottom: 0,
                        }}
                    >
                        {/* Show a custom tooltip */}
                        <Tooltip
                            labelFormatter={(week_nr) => study_week_nr_to_string(general.study_start_date,
                                week_nr, get_text(general, "months", null, language, null,
                                    null, null, false, false,
                                    false, false, true),
                                false)}
                            formatter={(value) => number_to_string(round_number(value, 2),
                                null, language)}
                        />
                        <XAxis
                            padding={{ left: 20, right:20 }}
                            dataKey="week_nr"
                            minTickGap={12}
                            tickFormatter={(week_nr) => study_week_nr_to_string(general.study_start_date,
                                week_nr,
                                get_text(general, "months_short", null, language, null,
                                    null, null, false, false,
                                    false, false, true))}
                        />
                        <YAxis
                            yAxisId={"yas_left"}
                            unit={active_data_unit}
                            tickCount={6}
                            type="number"
                            domain={['auto', 'auto']}
                        />
                        {has_second_yas ? <YAxis
                            yAxisId={"yas_right"}
                            unit={selected_data_unit}
                            orientation="right"
                            tickCount={6}
                            type="number"
                            domain={['auto', 'auto']}
                        /> : null}
                        <Line
                            dataKey={main_block_title}
                            yAxisId="yas_left"
                            connectNulls
                            type="monotone"
                            stroke="#15aac4"
                            fill="#15aac4"
                            strokeWidth={3}
                            unit={active_data_unit}
                        />
                        {compare_block !== null ? <Line
                            dataKey={compare_title}
                            yAxisId={has_second_yas ? "yas_right" : "yas_left"}
                            connectNulls
                            type="monotone"
                            stroke="#221c1c"
                            fill="#221c1c"
                            unit={selected_data_unit}
                        /> : null }
                    </LineChart>
                </ResponsiveContainer> }
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        blocks_data_loaded: state.coronaBarometerReducer.blocks_data_loaded_selected,
        blocks: state.coronaBarometerReducer.blocks,
        blocks_data: state.coronaBarometerReducer.blocks_data,
        main_block_id: state.timeOverviewModalReducer.main_block_id,
        selected_block_id: state.timeOverviewModalReducer.selected_block_id,
        selected_data_filters: state.coronaBarometerReducer.selected_data_filters,
        default_filter_id: state.coronaBarometerReducer.default_filter_id,
        selection_type: state.timeOverviewModalReducer.selection_type,
        general: state.coronaBarometerReducer.general,
        active_data_filters: state.coronaBarometerReducer.active_data_filters,
        week_ids: state.coronaBarometerReducer.week_ids,
        block_filters: state.coronaBarometerReducer.block_filters,
    }
};


export default withRouter(connect(mapStateToProps)(LineGraph));
