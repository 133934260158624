import PropTypes from 'prop-types'
import React, {Component} from 'react';
import styles from './Header.module.css';
import Logo from './logo.svg';
import { Link } from 'react-router-dom';
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import {create_url, isMobile} from "../../../utilities";
import {withRouter} from "react-router";

class Header extends Component {
    /* Component to render the header of the website
    */
    render() {
        const {title, date_info, ...rest} = this.props;

        // get the language selector style
        const language_selector_styles = [styles.language_selector];
        if (isMobile) {
            language_selector_styles.push(styles.language_selector_mobile)
        }
        // render the header
        return (
            <React.Fragment>
                <div className={styles.header} {...rest}>
                    <Link to={create_url("/", this.props)}><img src={Logo} className={styles.logo} alt="" /></Link>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.header_content_right}>
                        <LanguageSelector className={language_selector_styles.join(" ")}/>
                    </div>
                </div>
                <div className={styles.header_fill} />
            </React.Fragment>
        );
    }
}


export default withRouter(Header);


Header.propTypes = {
  title: PropTypes.object.isRequired
}
