import PropTypes from 'prop-types'
import React, { Component } from 'react';
import {check_not_empty} from "../../../utilities";
import ReactLoader from 'react-loader-spinner';

class Loader extends Component {
    /* Component to show a loader (three dots)
    Use the react-loader-spinner plugin
    url: https://www.npmjs.com/package/react-loader-spinner
    */
    render() {
        const { margin_top, margin_bottom } = this.props;
        let proc_margin_top = 0;
        if(check_not_empty(margin_top)) {
            proc_margin_top = margin_top
        }
        let proc_margin_bottom = 0;
        if(check_not_empty(margin_bottom)) {
            proc_margin_bottom = margin_bottom
        }
        return <div style={{ float: "left", width: "100%"}}>
            <div style={{
                width: '50px',
                margin: `${proc_margin_top}px auto ${proc_margin_bottom}px auto`}}
            >
                <ReactLoader type="ThreeDots" color="#15aac4" height="25" width="50"  />
            </div>
        </div>
    }
}
export default Loader;


Loader.propTypes = {
  margin_top: PropTypes.number, // margin above the loader
  margin_bottom: PropTypes.number // margin below the loader
};
