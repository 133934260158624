import PropTypes from 'prop-types'
import React, { Component } from 'react';
import styles from './BlockBarGraph.module.css';
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer
} from 'recharts';
import {get_text, isMobile, number_to_string} from '../../../../utilities';


class BlockBarGraph extends Component {
    /* Component to add a bar graph to a statistic block on a blocks page */
    render() {
        const { data, in_modal, language } = this.props;
        // Default graph object settings which can be overridden in the website setup json file
        const graph_data = {
            layout: "vertical",
            width:"100%",
            height: 190,
            bar_size: 28,
            data: [],
            ...data,
            margin: {
                top: 0,
                right: 15,
                left: 20,
                bottom: 10,
                ...data.margin,
            },
            x_axis: {
                data_key: null,
                type: "number",
                axis_line: false,
                unit: "",
                start: 0,
                end: null,
                interval: 0,
                num_ticks: 5,
                num_ticks_mobile: null,
                label: "",
                label_props: {},
                tick: {},
                ...data.x_axis
            },
            y_axis: {
                data_key: "name",
                type: "category",
                axis_line: false,
                unit: "",
                start: 0,
                end: null,
                interval: 0,
                num_ticks: 5,
                num_ticks_mobile: 5,
                width: 60,
                tick_width: 55,
                label_distance: 45,
                label: "",
                label_props: {},
                ...data.y_axis
            },
            tooltip: {
                add_label: true,
                label: null,
                ...data.tooltip
            },
            bars: (data.bars && data.bars.length) > 0 ? data.bars :
                [
                    {
                        data_key: "value",
                        color: "#11a8c2",
                        label: null,
                        unit: null,
                    },
                ],
        };

        // handle graph style
        const graph_box_styles = [styles.graph];
        if(in_modal) {
            graph_box_styles.push(styles.graph_box_in_modal);
        } else {
            graph_box_styles.push(styles.graph_box);
        }
        if(isMobile) {
            graph_box_styles.push(styles.graph_box_mobile);
        }

        if(graph_data.data.length === 0) {
            return null;
        }

        // Render the block
        return (
            <div className={graph_box_styles.join(" ")}>
                <ResponsiveContainer
                    width={graph_data.width}
                    height={graph_data.height}
                >
                    <BarChart
                        layout={graph_data.layout}
                        barSize={graph_data.bar_size}
                        data={graph_data.data}
                        margin={graph_data.margin}
                    >
                        <XAxis
                            dataKey={graph_data.x_axis.data_key}
                            type={graph_data.x_axis.type}
                            unit={graph_data.x_axis.unit}
                            axisLine={graph_data.x_axis.axis_line}
                            domain={[graph_data.x_axis.start, graph_data.x_axis.end]}
                            interval={graph_data.x_axis.interval}
                            tickCount={graph_data.x_axis.num_ticks_mobile !== null && isMobile ? graph_data.x_axis.num_ticks_mobile : graph_data.x_axis.num_ticks}
                            tick={graph_data.x_axis.tick}
                            label={{
                               value: get_text(graph_data.x_axis.label, null, null, language),
                               position: 'inside',
                               dy: 15,
                               ...graph_data.x_axis.label_props,
                           }}
                        />
                        <YAxis
                            dataKey={graph_data.y_axis.data_key}
                            type={graph_data.y_axis.type}
                            unit={graph_data.y_axis.unit}
                            axisLine={graph_data.y_axis.axis_line}
                            domain={[graph_data.y_axis.start, graph_data.y_axis.end]}
                            interval={graph_data.y_axis.interval}
                            width={graph_data.y_axis.width}
                            tickCount={graph_data.y_axis.num_ticks_mobile !== null && isMobile ? graph_data.y_axis.num_ticks_mobile : graph_data.y_axis.num_ticks}
                            tick={{"width": graph_data.y_axis.tick_width}}
                            label={{
                                value: get_text(graph_data.y_axis.label, null, null, language),
                                angle: -90,
                                position: 'inside',
                                dx: graph_data.y_axis.label_distance *-1,
                                multiLine: true,
                                ...graph_data.y_axis.label_props
                            }}
                        />
                        <Tooltip
                            cursor={{fill: "#f6f6f6"}}
                            labelFormatter={(label) => {
                                if(graph_data.tooltip.add_label) {
                                    if(graph_data.tooltip.label &&
                                        graph_data.tooltip.label !== "") {
                                        return `${get_text(graph_data.tooltip.label, null, null, language)} ${get_text(label, null, null, language)}`
                                    }
                                    return get_text(label, null, null, language)
                                }
                                return null
                            }}
                            formatter={(value) => number_to_string(value, null, language)}
                        />
                        {graph_data.bars.map((bar_data) => <Bar
                            key={bar_data.data_key}
                            dataKey={bar_data.data_key}
                            fill={bar_data.color}
                            name={get_text(bar_data.label, null, null, language)}
                            unit={bar_data.unit} />)}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

export default BlockBarGraph;

BlockBarGraph.propTypes = {
  data: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  in_modal: PropTypes.bool
};
