import PropTypes from 'prop-types'
import React, { Component } from 'react';
import styles from "./TextContent.module.css";
import TextButton from "../../../../UI/TextButton/TextButton";
import {withRouter} from "react-router";
import TextLabel from "../../../../SupportedComponents/TextLabel/TextLabel";

class TextContent extends Component{

    render() {
        const {sidebar_data} = this.props;
        return <React.Fragment>
                <div className={styles.content}>
                    <div className={styles.description}>
                        <TextLabel
                            content={sidebar_data.description}
                            process_html />
                    </div>
                    {sidebar_data.links.map((link_data, index) =>
                        <TextButton key={`button_${index}`}
                                    data={link_data}
                                    arrowDirection="right"/>)}
                </div>
            </React.Fragment>
    }
}

export default withRouter(TextContent);

TextContent.propTypes = {
  sidebar_data: PropTypes.object.isRequired
};
