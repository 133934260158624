import PropTypes from 'prop-types'
import React, {Component} from 'react';
import styles from './IntroSidebar.module.css';
import Icon from "../../../UI/Icon/Icon";
import {isMobile, updateObject} from '../../../../utilities';
import FilterSidebarContent from "./FilterSidebarContent/FilterSidebarContent";
import TextContent from "./TextContent/TextContent";
import SidebarToggleTitle from "./SidebarToggleTitle/SidebarToggleTitle";
import {Collapse} from "react-collapse/lib/Collapse";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {SIDEBAR_ITEM_FILTERS, SIDEBAR_ITEM_INTRO} from "../../../../Constants/sidebar_item_constants";
import Socials from "../../../UI/Socials/Socials";
import TextLabel from "../../../SupportedComponents/TextLabel/TextLabel";

// Default sidebar object settings which can be overridden in the website setup json file
const default_sidebar_data = {
    title: null,
    description: null,
    links: []
};

class IntroSidebar extends Component {
    /* Component to show the sidebar of the blocks page
    * */
    constructor(props) {
        super(props);
        // state to toggle the different parts of the sidebar
        this.state = {
            show_intro: true,
            show_filters: false
        }
    }

    toggle_sidebar_item = () => {
        // method to handle the toggle clicks of the sidebar items
        const {set_active_sidebar_item, active_sidebar_item} = this.props;
        if(active_sidebar_item === SIDEBAR_ITEM_INTRO) {
            set_active_sidebar_item(SIDEBAR_ITEM_FILTERS)
        } else {
            set_active_sidebar_item(SIDEBAR_ITEM_INTRO)
        }
    }

    render() {
        const {general, data, is_open, toggle_handler, active_sidebar_item,
            set_active_sidebar_item, ...rest} = this.props;
        // Merge the default sidebar settings with the settings from the json settings file
        const sidebar_data = updateObject(default_sidebar_data, data)

        // handle the styles
        const used_styles = [styles.intro_block];
        const styles_toggle_button = [styles.toggle_button];

        // handle the style of the mobile version
        if(isMobile) {
            used_styles.push(styles.intro_block_mobile);
        } else {
            used_styles.push(styles.intro_block_pc);
            styles_toggle_button.push(styles.toggle_button_desktop);
        }

        // handle the open / close functionality
        if(is_open) {
            used_styles.push(styles.open);
        } else {
            if(isMobile) {
                used_styles.push(styles.close_mobile);
            } else {
                used_styles.push(styles.close);
            }
            styles_toggle_button.push(styles.toggle_button_closed);
        }

        // Render the sidebar
        return (
            <div className={used_styles.join(" ")} {...rest}>
                {/* Sidebar header which contains the open/close button */}
                <div className={styles.header}>
                    <div className={styles_toggle_button.join(" ")}
                         onClick={toggle_handler}>
                        <Icon icon="arrow-left"/>
                    </div>
                </div>
                <div className={styles.content}
                     style={ isMobile ? {"height": window.innerHeight - 102} : {"height": window.innerHeight - 122}}>
                    <div className={isMobile ? styles.content_inner_mobile : styles.content_inner}>
                        {/* The filter part of the sidebar */}
                        <SidebarToggleTitle
                            icon={'loop'}
                            title={<TextLabel use_general id="filter_title" />}
                            collapsed={active_sidebar_item === SIDEBAR_ITEM_FILTERS}
                            onClick={this.toggle_sidebar_item}
                        />
                        <Collapse isOpened={active_sidebar_item === SIDEBAR_ITEM_FILTERS}>
                            <FilterSidebarContent/>
                        </Collapse>
                        {/* The page information part of the sidebar */}
                        <SidebarToggleTitle
                            title={<TextLabel content={sidebar_data.title} />}
                            collapsed={active_sidebar_item === SIDEBAR_ITEM_INTRO}
                            onClick={this.toggle_sidebar_item}
                            show_top_line
                        />
                        <Collapse isOpened={active_sidebar_item === SIDEBAR_ITEM_INTRO}>
                            <div style={{"minHeight": window.innerHeight - 295}}>
                                <TextContent sidebar_data={sidebar_data} />
                            </div>
                        </Collapse>
                    </div>
                    {/* Show the socials in the footer of the sidebar */}
                    <div className={styles.socials}>
                        <Socials />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        general: state.coronaBarometerReducer.general,
        active_sidebar_item: state.coronaBarometerReducer.active_sidebar_item
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        set_active_sidebar_item: (active_sidebar_item) => dispatch(actions.corona_barometer_set_active_sidebar_item(active_sidebar_item))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroSidebar);


IntroSidebar.propTypes = {
  animation_ready: PropTypes.bool, // if the open/close animation is ready
  data: PropTypes.object.isRequired, // the data of the sidebar from the settings file
  is_open: PropTypes.bool, // if the sidebar is open or closed
  toggle_handler: PropTypes.func.isRequired, // reference to the method which handles the toggle of the sidebar
}

