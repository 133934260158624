import * as actionTypes from './../actions/actionTypes';
import {updateObject} from "../../utilities";

const initState = {
    icons: {}
};

const iconsReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.ICONS_SET_ICONS: return updateObject(state, {icons: action.icons});
        default: return state
    }
};

export default iconsReducer;
