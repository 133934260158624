import PropTypes from 'prop-types'
import React, {Component, createRef} from 'react';
import BaseBlock from "../BaseBlock/BaseBlock";
import BlockHeader from "../../UI/BlockElements/BlockHeader/BlockHeader";
import BlockPercentage from "../../UI/BlockElements/BlockPercentage/BlockPercentage";
import BlockDescription from "../../UI/BlockElements/BlockDescription/BlockDescription";
import ReferenceFooter from "../../UI/BlockElements/ReferenceFooter/ReferenceFooter";
import { connect } from 'react-redux';
import {
    check_not_empty,
    get_block_categories,
    get_filter_key,
    get_language_from_props,
    get_latest_data_week_key,
    get_text,
    get_week_number_from_key,
    isMobile,
    round_number,
    updateObject
} from "../../../utilities";
import * as actions from "../../../store/actions";
import {withRouter} from "react-router";
import Ink from 'react-ink';
import {
    FOOTER_REFERENCE_TYPE_MEAN,
    FOOTER_REFERENCE_TYPE_PREVIOUS
} from "../../../Constants/footer_block_reference_type_constants";
import Loader from "../../UI/Loader/Loader";
import TextLabel from "../../SupportedComponents/TextLabel/TextLabel";

// Default block object settings which can be overridden in the website setup json file
const default_block_data = {
    icon: null,
    title: null,
    id: null,
    category: null,
    description: null,
    number_sub_text: null,
    unit: null,
    reference_increase: {
        color: "negative",
        arrow: "positive"
    },
    reference_decrease: {
        color: "positive",
        arrow: "negative"
    },
    reference_stable: {
        color: "neutral",
        arrow: null
    },
};


class SingleStatsBlock extends Component {
    /*
    * Component to create a block with a number statistic as number.
    * Component will be used if the block type is set to "number".
    * */
    constructor(props) {
        super(props);
        this.inkRef = createRef();
    }

    open_modal_handler = () => {
        // Method to open the comparison modal
        this.props.open_modal(this.props.block.id);
    };

    render() {
        const {block, active_data_filters, default_filter_id, block_filters, blocks_data, week_ids, current_week,
            block_footer_reference_type, blocks_data_loaded} = this.props;
        // Merge the default block settings with the settings from the json settings file
        const block_merged = updateObject(default_block_data, block);
        // Get current selected language from the router props
        const language = get_language_from_props(this.props);

        // Get classes
        const gridItemClassName = get_block_categories(block_merged, true);
        let block_content = <Loader margin_top={125} />;

        // Show block if the block data is loaded
        if(blocks_data_loaded) {
            // Get the filter key of the active filters
            const filter_key = get_filter_key(active_data_filters, block_filters);
            // Get the block data
            const block_data = blocks_data[filter_key][block_merged.id];
            // Setup variables which are used to create the block content
            let cur_value, footer_color, footer_arrow, footer_week_difference, footer_value;

            // List of footer keys to find the right text for in the footer
            const footer_text_key = ["stats_block_footer_references"];

            // Object which are used to save the replace values for the block description
            const block_description_replace_values = {};

            if(block_data === undefined) {
                // Hide the block by adding the "hide_block" class to the grid classes
                gridItemClassName.push("hide_block");
            } else {
                // Get the most recent week key available for this statistic
                const cur_data_key = get_latest_data_week_key(block_data, week_ids);
                if(cur_data_key === null) {
                    // Hide the block by adding the "hide_block" class to the grid classes
                    cur_value = 0;
                    gridItemClassName.push("hide_block");
                } else {
                    // process the current number which will be showed in the block
                    cur_value = round_number(block_data[cur_data_key]);
                }

                //
                // Process the footer reference info
                //

                if(block_footer_reference_type === FOOTER_REFERENCE_TYPE_MEAN) {
                    // Show the mean in the footer

                    // add the mean class to the footer references key list
                    footer_text_key.push("mean");

                    // Get the average value of the block (equal to the value which are showed when no filters are used, which are the "default" values)
                    const cur_footer_key = get_latest_data_week_key(
                        blocks_data[default_filter_id][block_merged.id], week_ids);
                    if(cur_footer_key !== null) {
                        // Process the average value
                        footer_value = round_number(
                            blocks_data[default_filter_id][block_merged.id][cur_footer_key]);

                        // Check if the average is the same as the current value
                        if(cur_value === footer_value) {
                            // Average is the same as current value, so add the equal key to the footer keys
                            footer_text_key.push("equal");
                        } else {
                            // Average is not the same as current value, so add the not equal key to the footer keys
                            footer_text_key.push("not_equal");
                        }
                    }
                } else if(block_footer_reference_type === FOOTER_REFERENCE_TYPE_PREVIOUS) {
                    // Show the previous value in the footer

                    // Add the previous key in the footer reference key list
                    footer_text_key.push("previous");

                    // get the data of the second last data point (skip one week in the data key selection)
                    const cur_footer_key = get_latest_data_week_key(block_data, week_ids, 1);
                    if(cur_footer_key !== null) {
                        // Get the week number from the key
                        const footer_week_number =  get_week_number_from_key(cur_footer_key);
                        // Process the average value
                        footer_value = round_number(block_data[cur_footer_key]);
                        // calculate the week difference between the last and second last data point
                        footer_week_difference = current_week - footer_week_number;

                        // Check if the week difference is one or multiple weeks and add the right key to
                        // the footer key list
                        if(footer_week_difference > 1) {
                            footer_text_key.push("multiple_weeks");
                        } else {
                            footer_text_key.push("single_week");
                        }

                        // Check if the last and second last value is equal to each other and add the right key to
                        // the footer key list
                        if(cur_value === footer_value) {
                            footer_text_key.push("equal");
                        } else {
                            footer_text_key.push("not_equal");
                        }
                    }
                }
                // Set the right footer color and arrow based on the increase or decrease of the values in the block
                // and footer and based on the settings if an increase is positive or negative, or a decrease is
                // positive or negative. If both values are equally, then the neutral settings are used
                if(footer_text_key.length > 2) {
                    if(cur_value > footer_value) {
                        footer_arrow = block_merged.reference_increase.arrow;
                        footer_color = block_merged.reference_increase.color;
                    } else if(cur_value < footer_value) {
                        footer_arrow = block_merged.reference_decrease.arrow;
                        footer_color = block_merged.reference_decrease.color;
                    } else {
                        footer_arrow = block_merged.reference_stable.arrow;
                        footer_color = block_merged.reference_stable.color;
                    }
                }

                // find statistics inside the block_merged.number_sub_text and add them to the replace object
                const num_text = get_text(block_merged.number_sub_text, null, null, language);
                if(check_not_empty(num_text)) {
                    // Use regex to find the shortcodes which is located between brackets []
                    const paramsPattern = /[^[\\]+(?=])/g;
                    const extractKeys = num_text.match(paramsPattern);
                    if(extractKeys !== null) {
                        // Loop though all the shortcode keys
                        extractKeys.forEach((key) => {
                            //  get the value corresponded to the shortcode
                            let value = blocks_data[filter_key][key][cur_data_key];
                            if(check_not_empty(value)) {
                                // Round the number
                                value = round_number(value);
                                // Add the key and value to the replace key,
                                // set the option that the value must be processed as number and add unit as suffix
                                block_description_replace_values[key] = {
                                    "value": value,
                                    "as_number": true,
                                    "suffix": block_merged.unit
                                }
                            }
                        });
                    }
                }
            }

            // Create the content of the block
            block_content = <React.Fragment>
                <BlockHeader icon={block_merged.icon}
                             description={check_not_empty(block_merged.description) ?
                                 <TextLabel content={block_merged.description} process_html /> : null}>
                             <TextLabel content={block_merged.title} process_html/>
                </BlockHeader>
                <BlockPercentage>
                    <TextLabel
                        content={cur_value}
                        as_number
                        suffix={block_merged.unit}/>
                </BlockPercentage>
                <BlockDescription><TextLabel
                    content={block_merged.number_sub_text}
                    replace_values={block_description_replace_values}
                    process_html
                />
                </BlockDescription>
                {/* Add the footer to the block and add the week number value as replace key and the value as replace key */}
                {footer_text_key.length > 2 ? <ReferenceFooter color={footer_color} arrow={footer_arrow}>
                    <TextLabel
                        use_general
                        id={footer_text_key}
                        first_letter_capital
                        replace_values={{
                            "week": {
                                "value": footer_week_difference,
                                "number_as_string": true,
                            },
                            "value": {
                                "value": footer_value,
                                "as_number": true,
                                "suffix": block_merged.unit
                            }
                        }}
                    />
                </ReferenceFooter> : null}
                {/* Add click effect if block is clickable */}
                <Ink
                    style={{
                        borderRadius: "12px",
                        left: "10px",
                        top: "10px",
                        width: "calc(100% - 20px)",
                        height: "calc(100% - 20px)",
                    }}
                    ref={this.inkRef}
                    duration={500}
                    opacity={0.25}
                    radius={1500}
                    hasTouch={isMobile}
                />
            </React.Fragment>
        }
        // Return the block content with the basic block wrapper
        return (
            <BaseBlock
                gridItemClassName={gridItemClassName.join(" ")}
                onClick={this.open_modal_handler}
            >
                {block_content}
            </BaseBlock>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        blocks_data_loaded: state.coronaBarometerReducer.blocks_data_loaded,
        current_week: state.coronaBarometerReducer.current_week,
        active_data_filters: state.coronaBarometerReducer.active_data_filters,
        default_filter_id: state.coronaBarometerReducer.default_filter_id,
        block_filters: state.coronaBarometerReducer.block_filters,
        blocks_data: state.coronaBarometerReducer.blocks_data,
        block_footer_reference_type: state.coronaBarometerReducer.block_footer_reference_type,
        week_ids: state.coronaBarometerReducer.week_ids,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        open_modal: (block) => dispatch(actions.time_overview_modal_set_modal_open(block)),
    }
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SingleStatsBlock));

SingleStatsBlock.propTypes = {
  blockIndex: PropTypes.number.isRequired, // number of the block on the blocks page
  block: PropTypes.object.isRequired, // Object of the block from the json setup file
};
