import { Component } from 'react';
import {withRouter} from "react-router";

class ScrollToTop extends Component {
    // Method to fix that the page will scroll to the top after page changing.
    // based on discussion: https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)
