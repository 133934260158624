import PropTypes from 'prop-types'
import React, { Component } from 'react';
import Icon from "../Icon/Icon";
import styles from './FilterButton.module.css';
import { isMobile } from '../../../utilities';

class FilterButton extends Component {
    /* Component to create the filter button
    * */

    render() {
        const {onClick, icon, active, children, small, gray, disabled, ...rest} = this.props;
        const text = children;
        const button_styles = [];

        // handle the small button version
        if(small !== undefined && small !== null && small === true) {
            button_styles.push(styles.button_small);
        } else {
            button_styles.push(styles.button);
        }
        // Create the gray button
        if(gray !== undefined && gray !== null && gray === true) {
            button_styles.push(styles.button_gray);
        }

        // Handle mobile style
        if(isMobile) {
            button_styles.push(styles.button_mobile)
        } else {
            button_styles.push(styles.button_desktop)
        }

        // Handle the disable and active button styles
        if(active && disabled) {
            button_styles.push(styles.disabled_active);
        }
        else if(disabled) {
            button_styles.push(styles.disabled);
        }
        else if(active) {
            button_styles.push(styles.active);
        }

        // Render the button
        return (
            <div
                onClick={disabled ? null : onClick}
                className={button_styles.join(" ")}
                {...rest}>
                {icon ? <Icon icon={icon} label={text} /> : null}
                <span>{text}</span>
                </div>
        )
    }
}


export default FilterButton;


FilterButton.propTypes = {
  active: PropTypes.bool, // if the button is active
  children: PropTypes.object, // text of the button
  disabled: PropTypes.bool, // disbale the button,
  gray: PropTypes.bool, // gray version
  icon: PropTypes.string, // path to the icon which will be added in the front of the button
  onClick: PropTypes.func.isRequired, // reference to the click handler method
  small: PropTypes.bool // Render the small version
}
