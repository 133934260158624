import PropTypes from 'prop-types'
import React, { Component, createRef } from 'react';
import styles from './TimeOverviewHeader.module.css';
import Icon from "../../Icon/Icon";
import {check_not_empty, isMobile} from "../../../../utilities";

class TimeOverviewHeader extends Component {
    /* Component to create the header of the modal or the mobile comparison page */
    constructor(props) {
        super(props);
        this.infoIconRef = createRef();
        this.state = {
            show_description: false
        }
    }
    show_description = () => {
        // method to handle to show the box with the variable description
        if(check_not_empty(this.props.info_description)){
            this.setState(() => {
                return {
                    show_description: true
                }
            })
        }
    };

    hide_description = () => {
        // method to handle to hide the box with the variable description
        if(check_not_empty(this.props.info_description)){
            this.setState(() => {
                return {
                    show_description: false
                }
            })
        }
    };

    render() {
        const {icon, title, description, info_description, modalCloseHandler,
            compareButtonHandler, compareButtonText, ...rest} = this.props;
        let text = title;
        if(text === undefined || text === null) {
            text = "";
        }
        // handle the styling
        const block_styles = [styles.block];
        const icon_style_classes = [styles.icon];
        const title_style_classes = [styles.title];
        const description_style_classes = [styles.description];
        const info_icon_style_classes = [styles.info_icon];
        const info_box_style_classes = [styles.info_box];
        if(isMobile) {
            block_styles.push(styles.block_mobile);
            icon_style_classes.push(styles.icon_mobile);
            title_style_classes.push(styles.title_mobile);
            info_icon_style_classes.push(styles.info_icon_mobile);
            info_box_style_classes.push(styles.info_box_mobile);
        } else {
            description_style_classes.push(styles.description_desktop);
        }
        // create the header
        return (
            <div className={block_styles.join(" ")} {...rest}>
                {icon ? <div className={icon_style_classes.join(" ")}><Icon icon={icon} /></div> : null }
                {<div className={title_style_classes.join(" ")}>{text}</div>}
                {isMobile && compareButtonHandler ? <div className={styles.button_selector}
                     onClick={compareButtonHandler}>
                    <Icon icon="plus-white"/>
                    <span>{compareButtonText}</span>
                </div> : null }
                <div className={description_style_classes.join(" ")}>
                    {check_not_empty(description) ? <div className={styles.description_text}>
                        {description}
                    </div> : null}
                    {check_not_empty(info_description) ? <div
                        className={info_icon_style_classes.join(" ")}
                        onMouseEnter={this.show_description}
                        onMouseLeave={this.hide_description}
                        ref={this.infoIconRef}
                    >i</div> : null}
                </div>
                {this.state.show_description ?  <div
                    className={info_box_style_classes.join(" ")}
                    style= {
                        {
                            left: `${isMobile ? this.infoIconRef.current.offsetLeft - 219 : this.infoIconRef.current.offsetLeft - 369}px`,
                            top: `${this.infoIconRef.current.offsetTop + 19}px`
                        }
                    }
                >
                    {info_description}</div> : null}
                {!isMobile ? <Icon icon={'cross'}
                      icon_hover={'cross_hover'}
                      onClick={modalCloseHandler}
                      className={styles.exit_icon}
                /> : null}
            </div>
        );
    }
}

export default TimeOverviewHeader;


TimeOverviewHeader.propTypes = {
  compareButtonHandler: PropTypes.func,
  compareButtonText: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  info_description: PropTypes.string,
  modalCloseHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};
