import * as actionTypes from './../actions/actionTypes';
import {updateObject} from "../../utilities";
import {FOOTER_REFERENCE_TYPE_PREVIOUS} from "../../Constants/footer_block_reference_type_constants";
import {SIDEBAR_ITEM_INTRO} from "../../Constants/sidebar_item_constants";

const initState = {
    general: {},
    pages: {},
    blocks: {},
    blocks_data_loaded: false,
    blocks_data_loaded_selected: false,
    active_sidebar_item: SIDEBAR_ITEM_INTRO,
    blocks_data: {},
    data_categories: [],
    block_filters: [],
    active_data_filters: {},
    selected_data_filters: {},
    default_filter_id: null,
    week_ids: null,
    current_week: -1,
    block_footer_reference_type: FOOTER_REFERENCE_TYPE_PREVIOUS,
    block_footer_reference_type_by_user: FOOTER_REFERENCE_TYPE_PREVIOUS,
    loaded: false,
};

const coronaBaroneterReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.CORONA_BAROMETER_SET_GENERAL_INFO: return updateObject(state, {general: action.general});
        case actionTypes.CORONA_BAROMETER_SET_PAGES: return updateObject(state, {pages: action.pages});
        case actionTypes.CORONA_BAROMETER_SET_BLOCKS: return updateObject(state, {blocks: action.blocks});
        case actionTypes.CORONA_BAROMETER_SET_BLOCKS_DATA: return updateObject(state, {blocks_data: action.blocks_data});
        case actionTypes.CORONA_BAROMETER_SET_DATA_CATEGORIES: return updateObject(state, {data_categories: action.data_categories});
        case actionTypes.CORONA_BAROMETER_SET_LOADED: return updateObject(state, {loaded: action.loaded});
        case actionTypes.CORONA_BAROMETER_SET_WEEK_IDS: return updateObject(state, {week_ids: action.week_ids});
        case actionTypes.CORONA_BAROMETER_SET_CURRENT_WEEK: return updateObject(state, {current_week: action.current_week});
        case actionTypes.CORONA_BAROMETER_SET_BLOCK_FILTERS: return updateObject(state, {block_filters: action.block_filters});
        case actionTypes.CORONA_BAROMETER_SET_ACTIVE_DATA_FILTERS: return updateObject(state, {active_data_filters: action.active_data_filters});
        case actionTypes.CORONA_BAROMETER_SET_SELECTED_DATA_FILTERS: return updateObject(state, {selected_data_filters: action.selected_data_filters});
        case actionTypes.CORONA_BAROMETER_SET_DEFAULT_FILTER_ID: return updateObject(state, {default_filter_id: action.default_filter_id});
        case actionTypes.CORONA_BAROMETER_SET_BLOCK_FOOTER_REFERENCE_TYPE: return updateObject(state, {block_footer_reference_type: action.block_footer_reference_type});
        case actionTypes.CORONA_BAROMETER_SET_BLOCK_FOOTER_REFERENCE_TYPE_BY_USER: return updateObject(state, {block_footer_reference_type_by_user: action.block_footer_reference_type});
        case actionTypes.CORONA_BAROMETER_SET_BLOCKS_DATA_LOADED: return updateObject(state, {blocks_data_loaded: action.blocks_data_loaded});
        case actionTypes.CORONA_BAROMETER_SET_BLOCKS_DATA_LOADED_SELECTED: return updateObject(state, {blocks_data_loaded_selected: action.blocks_data_loaded_selected});
        case actionTypes.CORONA_BAROMETER_SET_ACTIVE_SIDEBAR_ITEM: return updateObject(state, {active_sidebar_item: action.active_sidebar_item});
        default: return state
    }
};

export default coronaBaroneterReducer;
