import PropTypes from 'prop-types';
import React, {Component} from 'react';
import BaseBlock from "../BaseBlock/BaseBlock";
import BlockHeader from "../../UI/BlockElements/BlockHeader/BlockHeader";
import BlockBarGraph from "../../UI/BlockElements/BlockBarGraph/BlockBarGraph";
import {
    check_not_empty,
    get_block_categories,
    get_filter_key, get_language_from_props,
    get_latest_data_week_key, get_text, isMobile,
    round_number,
    updateObject
} from "../../../utilities";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import * as actions from "../../../store/actions";
import Ink from "react-ink";
import Loader from "../../UI/Loader/Loader";
import {TextLabelWithoutRedux} from "../../SupportedComponents/TextLabel/TextLabel";

// Default block object settings which can be overridden in the website setup json file
const default_block_data = {
    block_data: null,
    block_data_keys: null,
    icon: null,
    title: null,
    category: null,
    description: null,
    graph: {},
    graph_data_value_key: "value",
    number_sub_text: null,
    block_width_size: 2,
    block_height_size: 1,
    block_height_auto: false,
    graph_height: "auto"
};


class BarGraphBlock extends Component {
    /*
    * Component to create a block with a bar graph. Component will be used if the block type is set to "bar_graph".
    * */
    can_compare = () => {
        // Method to check if the data inside the block can be compared to other data by using a modal
        return this.props.block.disable_comparison === undefined || this.props.block.disable_comparison === false
    };

    open_modal_handler = () => {
        // Method to open the comparison modal
        if(this.can_compare()) {
            this.props.open_modal(this.props.block.id);
        }
    };

    render() {
        const {block, in_group_block, active_data_filters, block_filters, blocks_data, week_ids,
            blocks_data_loaded, icons, icons_init, general, match} = this.props;
        // Merge the default block settings with the settings from the json settings file
        const block_merged = updateObject(default_block_data, {
            ...block,
            graph: updateObject(default_block_data.graph, block.graph)
        });

        // Get current selected language from the router props
        const language = get_language_from_props(this.props);

        // Get classes
        const gridItemClassName = get_block_categories(block_merged, true);

        // Show by default the loader
        let block_content = <Loader margin_top={135 * block_merged.block_height_size - 10} margin_bottom={135 * block_merged.block_height_size - 10} />;

        // Show block if the block data is loaded
        if(blocks_data_loaded) {
            // Get the filter key of the active filters
            const filter_key = get_filter_key(active_data_filters, block_filters);
            // Get the block data
            const block_data = blocks_data[filter_key][block_merged.id];
            if (block_data !== undefined) {
                // Get the most recent week key available for this statistic
                const cur_week_key = get_latest_data_week_key(block_data, week_ids);
                if (cur_week_key !== null) {

                    // Get the most recent data
                    const current_data = block_data[cur_week_key];
                    if (check_not_empty(current_data) && Object.keys(current_data).length > 0) {
                        const updated_data = [];

                        //
                        // Process the data from the database or input file so that it can be used in the graph
                        //

                        // Loop though the data key settings
                        block_merged.graph.data.forEach((data_row) => {
                            if (data_row.hasOwnProperty('id')) {
                                const current_data_row = {...data_row};

                                // Check if the particular statistic row not must be hide in the block graph
                                if(data_row.hasOwnProperty("hide_in_block") === false || data_row["hide_in_block"] === false) {
                                    // add the label
                                    current_data_row["name"] = get_text(current_data_row["name"], null, null, language);

                                    // Check if the data key is a string (single bar) or a object which is used for a multi bar graph
                                    if (typeof block_merged.graph_data_value_key === 'string') {
                                        // Single bar graph, format the value
                                        if (current_data.hasOwnProperty(data_row['id'])) {
                                            current_data_row[block_merged.graph_data_value_key] =
                                                round_number(current_data[data_row['id']]);
                                        }
                                    } else {
                                        // Multi bar graph, process all the numbers of the different bars
                                        Object.keys(block_merged.graph_data_value_key).forEach((key) => {
                                            const data_key = block_merged.graph_data_value_key[key];
                                            current_data_row[data_key] = round_number(current_data[key][data_row['id']]);
                                        })
                                    }
                                    updated_data.push(current_data_row);
                                }
                            }
                        });
                        let calculated_graph_height = block_merged.graph_height;

                        // Calculate the height of the graph if the hide is not set manual
                        if(block_merged.graph_height === "auto") {
                            calculated_graph_height = 35 * updated_data.length + 40
                        }

                        // Update the block object
                        block_merged.graph = {
                            ...block_merged.graph,
                            height: calculated_graph_height,
                            data: updated_data,
                        };
                    }
                    // Create the JSX code of the content of the block
                    block_content = <React.Fragment>
                        <BlockHeader
                            icons_list={icons}
                            icons_init={icons_init}
                            icon={block_merged.icon}
                            description={<TextLabelWithoutRedux content={block_merged.description}
                                                                general={general}
                                                                match={match}
                                                                process_html />}
                            doubleWidth
                        >
                            <TextLabelWithoutRedux content={block_merged.title}
                                                   general={general}
                                                   match={match}
                                                   process_html />
                        </BlockHeader>
                        <BlockBarGraph data={block_merged.graph} language={language}/>
                        {/* Add click effect if block is clickable */}
                        { this.can_compare() ? <Ink
                            style={{
                                borderRadius: "12px",
                                left: "10px",
                                top: "10px",
                                width: "calc(100% - 20px)",
                                height: "calc(100% - 20px)",
                                zIndex: 10,
                            }}
                            duration={500}
                            opacity={0.25}
                            radius={1500}
                            hasTouch={isMobile}
                        /> : null}
                    </React.Fragment>
                } else {
                    // Hide the block by adding the "hide_block" class to the grid classes
                    gridItemClassName.push("hide_block");
                }
            } else {
                gridItemClassName.push("hide_block");
            }
        }
        // Return the block content with the basic block wrapper
        return (
            <BaseBlock
                gridItemClassName={gridItemClassName.join(" ")}
                onClick={this.can_compare() ? this.open_modal_handler : null}
                width_size={block_merged.block_width_size}
                height_size={block_merged.block_height_size}
                auto_height={block_merged.block_height_auto || isMobile}
                in_group_block={in_group_block}
            >
                {block_content}
            </BaseBlock>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        blocks_data_loaded: state.coronaBarometerReducer.blocks_data_loaded,
        active_data_filters: state.coronaBarometerReducer.active_data_filters,
        block_filters: state.coronaBarometerReducer.block_filters,
        blocks_data: state.coronaBarometerReducer.blocks_data,
        week_ids: state.coronaBarometerReducer.week_ids,
        page_has_block_filters: state.coronaBarometerReducer.page_has_block_filters,
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        open_modal: (block) => dispatch(actions.time_overview_modal_set_modal_open(block)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BarGraphBlock));
export const BarGraphBlockWithoutRedux = BarGraphBlock;


BarGraphBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired, // number of the block on the blocks page
    block: PropTypes.object.isRequired, // Object of the block from the json setup file
    in_group_block: PropTypes.bool // bool if block is located in a group block
};
