import PropTypes from 'prop-types'
import React, {Component} from 'react';
import GridItem from "../../Grid/GridItem/GridItem";
import {check_not_empty, isMobile} from '../../../utilities';
import styles from './BaseBlock.module.css';

class BaseBlock extends Component {
    /*
    * Base Block component with the basic functionality of every block.
    * Every block must use this component as "Wrapper" to enable filtering, and grid position alignment
    * */
    render() {
        const {className, gridItemClassName, width_size, height_size, auto_height, in_group_block, is_group_block,
            onClick, ...rest} = this.props;

        // Handling styling of the block
        const base_block_styles = [];
        if(is_group_block) {
            base_block_styles.push(styles.base_block_group);
        } else {
            base_block_styles.push(styles.base_block);
        }

        const base_block_inner_styles = [styles.inner];
        let onClickHandler = null;
        if(onClick !== undefined && onClick !== null) {
            onClickHandler = onClick;
            if(!isMobile) {
                base_block_inner_styles.push(styles.clickable)
            }
        }

        // Handle the size of the block
        let class_width_size_string = "width_1"
        let class_height_size_string = "height_1"
        if(check_not_empty(width_size)) {
            class_width_size_string = `width_${width_size}`;
        }
        if(check_not_empty(height_size)) {
            class_height_size_string = `height_${height_size}`;
        }

        // Handle size of the block when the website is visited on a mobile device
        if(isMobile) {
            class_width_size_string = `${class_width_size_string}_mobile`;
            if(auto_height !== undefined && auto_height !== null && auto_height === true) {
                class_height_size_string = `height_auto`;
            } else {
                class_height_size_string = `${class_height_size_string}_mobile`;
            }
        }
        if(is_group_block) {
            base_block_styles.push(styles[`${class_width_size_string}_group`]);
        } else {
            base_block_styles.push(styles[class_width_size_string]);
        }
        base_block_styles.push(styles[class_height_size_string]);

        // Handle auto height based on inner content
        if(auto_height !== undefined && auto_height !== null && auto_height === true) {
            base_block_inner_styles.push(styles.height_auto);
        } else {
            base_block_inner_styles.push(styles[class_height_size_string]);
        }
        base_block_inner_styles.push(className);

        // Blocks which are in the group block
        if(in_group_block) {
            if(gridItemClassName.includes("hide_block")) {
                return null;
            }

            return (
                <div className={styles.group_outer}>
                    <div className={base_block_styles.join(" ")} {...rest}
                         onClick={onClickHandler} >
                        <div className={base_block_inner_styles.join(" ")}>{this.props.children}</div>
                    </div>
                </div>
            )
        }

        // Blocks which are the group block
        if(is_group_block) {
            return (
                <GridItem className={gridItemClassName}
                          doubleWidth={width_size !== undefined && width_size > 1}
                >
                    <div className={base_block_styles.join(" ")} {...rest} >
                        {this.props.children}
                    </div>
                </GridItem>
            )
        }

        // Normal blocks
        return (
            <GridItem className={gridItemClassName}
                      doubleWidth={width_size !== undefined && width_size > 1}
            >
                <div className={base_block_styles.join(" ")} {...rest}
                     onClick={onClickHandler} >
                    <div className={base_block_inner_styles.join(" ")}>{this.props.children}</div>
                </div>
            </GridItem>
        )
    }
}


export default BaseBlock;


BaseBlock.propTypes = {
    children: PropTypes.any, // content of the block
    className: PropTypes.string, // classes which are given to the base block
    gridItemClassName: PropTypes.string, // classes which are passed through to the grid item component
    onClick: PropTypes.func, // onclick function
    width_size: PropTypes.any, // int, width size of the block
    height_size: PropTypes.any, // int, height size of the block
    auto_height: PropTypes.bool, // bool if the auto must be automatically scaled to the size of the content
    in_group_block: PropTypes.bool, // bool if block is located in a group block
    is_group_block: PropTypes.bool // bool if block is in a group block (block which contains other blocks)
};
