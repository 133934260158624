import PropTypes from 'prop-types'
import React, { Component } from 'react';
import Styles from './SelectorOption.module.css';

class SelectorOption extends Component {
    /* Component to create a variable filter option */
    render() {
        const {children, onClick, active, selected, ...rest} = this.props;
        const classes = [Styles.filter_option];

        if(active) {
            classes.push(Styles.active);
        }
        if(selected) {
            classes.push(Styles.selected);
        }
        let onclickHandler = null;
        if(active === false && selected === false) {
            onclickHandler = onClick;
            classes.push(Styles.selectable)
        }
        return (
            <li className={classes.join(" ")}
                onClick={onclickHandler}
                {...rest}
            >
                <span/>
                {children}
            </li>)
    }
}


export default SelectorOption;


SelectorOption.propTypes = {
  active: PropTypes.bool, // if option is active
  children: PropTypes.string.isRequired, // text of the button
  onClick: PropTypes.func.isRequired, // reference to the onclick method
  selected: PropTypes.bool // if the option is selected
};
