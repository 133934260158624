import PropTypes from 'prop-types'
import React, {Component} from 'react';
import IntroSidebar from "./IntroSidebar/IntroSidebar";
import Blocks from "./Blocks/Blocks";
import {isMobile, updateObject} from '../../../utilities';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";

// Default page object settings which can be overridden in the website setup json file
const default_page_data = {
    type: "blocks",
    path: null,
    info_sidebar: {},
    blocks: [],
    categories: []
};

class BlocksPage extends Component {
    /* Component to create the page with the statistic blocks. This page shows the Sidebar with the page information
    and the filter options, the category filtering, the blocks itself in the grid layout and the footer text
    below the blocks
    * */

    constructor(props) {
        super(props);
        // State is used to handle the toggle of the sidebar
        this.state = {
            show_sidebar: !isMobile,
            sidebar_animation_ready: true
        }
    }

    toggle_intro_block = () => {
        // Method to toggle (show/hide) the sidebar
        if(this.state.show_sidebar === false) {
            this.setState({
                show_sidebar: true,
                sidebar_animation_ready: false});
        } else {
            this.setState({
                show_sidebar: false,
                sidebar_animation_ready: false
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Set the sidebar_animation_ready state after the annimation of the sidebar is ready. This is used to know
        // the space available left for the part with the blocks
        if(prevState.show_sidebar !== this.state.show_sidebar) {
            setTimeout(() => {
                this.setState({
                    sidebar_animation_ready: true
                });
            }, 300);
        }
    };

    render() {
        const {data} = this.props;
        // Merge the default page settings with the settings from the json settings file
        const page_data = updateObject(default_page_data, data)

        // create the page content, which contains the intro sidebar and blocks part of the page
        return (
            <div>
                <IntroSidebar
                    data={page_data.info_sidebar}
                    toggle_handler={this.toggle_intro_block}
                    is_open={this.state.show_sidebar}
                    animation_ready={this.state.sidebar_animation_ready}
                />
                <Blocks
                    page_block_ids={page_data.block_ids}
                    category_ids={page_data.category_ids}
                    sidebar_open={this.state.show_sidebar}
                    sidebar_animation_ready={this.state.sidebar_animation_ready}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        active_data_filters: state.coronaBarometerReducer.active_data_filters,
    }
};

export default withRouter(connect(mapStateToProps)(BlocksPage));

BlocksPage.propTypes = {
  data: PropTypes.object.isRequired // data of the blocks from the settings json file
};
