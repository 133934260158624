import {AVAILABLE_LANGUAGES, LANGUAGE_DUTCH} from "./Constants/language_constants";

export const create_url = (url, props) => {
    const language = get_language_from_url(props, false);
    let url_parts = url.split("/");
    url_parts = url_parts.filter((part) => check_not_empty(part))
    if(url_parts.length > 0) {
        if(check_not_empty(language)) {
            return ["", language, url_parts.join("/")].join("/");
        }
        return ["", url_parts.join("/")].join("/");
    }
    if(check_not_empty(language)) {
        return ["", language].join("/");
    }
    return "/"
}

export const create_change_language_url = (new_language, props) => {
    if(check_not_empty(props.location)) {
        const url = props.location.pathname;
        let url_parts = url.split("/");
        url_parts = url_parts.filter((part) => check_not_empty(part))
        if(url_parts.length > 0) {
            const language_in_url = url_parts[0];
            if(AVAILABLE_LANGUAGES.includes(language_in_url)) {
                return ["", new_language, url_parts.slice(1).join("/")].join("/");
            }
            return ["", new_language, url_parts.join("/")].join("/");
        }
        return ["", new_language].join("/");
    }
    return "/"
}


export const get_language_from_props = (props, return_default= true) => {
    if(check_not_empty(props.match) &&
        check_not_empty(props.match.params) &&
        check_not_empty(props.match.params.language)) {
        return props.match.params.language;
    }
    if(return_default) {
        return LANGUAGE_DUTCH;
    }
    return null;
};

export const get_language_from_url = (props, return_default= true) => {
    if(check_not_empty(props.location)) {
        const url = props.location.pathname;
        let url_parts = url.split("/");
        url_parts = url_parts.filter((part) => check_not_empty(part))
        if(url_parts.length > 0) {
            const language_in_url = url_parts[0];
            if(AVAILABLE_LANGUAGES.includes(language_in_url)) {
                return url_parts[0]
            }
        }
    }
    if(return_default) {
        return LANGUAGE_DUTCH;
    }
    return null;
};



export const updateObject = (oldObject, newObject) => {
    return {
        ...oldObject,
        ...newObject
    };
};

export const getActionObject = (type, parameters = {}) => {
    return {
        type: type,
        ...parameters
    }
};

export const get_object_from_list = (object_list, id, id_column = "id") => {
    for(let index = 0; object_list.length > index; index++) {
        const current_object = object_list[index];
        if(current_object[id_column] === id) {
            return current_object
        }
    }
    return null;
}

export const isNumeric = (n) => {
    return !isNaN(n);
};

export const list_to_string = (items) => {
    if(items !== undefined && items !== null) {
        if(items.length === 0) {
            return ""
        }
        else  if(items.length === 1) {
            return items[0];
        }
        else  if(items.length > 1) {
            const loopItems = items.slice(0,items.length -1);
            let returnString =  loopItems.join(", ")
            returnString += " & " + items[items.length -1];
            return returnString
        }
    }
    return ""
};

export const ucFirst = (inputText) => {
    return inputText.charAt(0).toUpperCase() + inputText.slice(1);
};

export const round_number = (number, num_decimals=null) => {
    if(check_na_value(number) === null) {
        return 0;
    }
    if(num_decimals != null) {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: num_decimals,
            maximumFractionDigits: num_decimals,
        });
        return formatter.format(number);
    }
    if(number > 10) {
        return Math.round(number);
    } else if(number === 0) {
        return 0
    }
    else if(number < 0.1) {
        return Math.round(number * 100) / 100;
    }
    return Math.round(number * 10) / 10;
};

export const number_to_string = (number, unit = null, language=LANGUAGE_DUTCH) => {
    if(check_not_empty(number)) {
        let formatted_number = number.toString();
        if(language === LANGUAGE_DUTCH) {
            formatted_number = formatted_number.replace(".", ",");
        }
        if(check_not_empty(unit)) {
            return `${formatted_number}${unit}`;
        }
        return formatted_number;
    }
    return "";
};

export const int_to_text = (int_value, options) => {
    if(check_not_empty(int_value)) {
        if (int_value <= Object.keys(options).length) {
            return options[int_value.toString()]
        }
        return int_value.toString();
    }
    return "";
};

export const study_week_nr_to_string = (study_start_date, week_nr, month_strings, format_year_abbreviated=true) => {
    const week_date = new Date(study_start_date);
    week_date.setDate(week_date.getDate() + 7*(week_nr - 1 ));
    return date_to_string(week_date, month_strings, format_year_abbreviated);
};

export const date_to_string = (date, months_texts=null, format_year_abbreviated=true) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year_full = date.getFullYear().toString();
    const year_abbreviated = `’${year_full.slice(-2)}`
    let year = year_full

    if(format_year_abbreviated) {
        year = year_abbreviated
    }

    if(months_texts !== undefined && months_texts !== null) {
        return `${day} ${months_texts[month.toString()]} ${year}`;
    }
    return `${day}-${month}-${year}`;
};

export const check_text = (text, default_text = "") => {
    if(check_not_empty(text)) {
        return text;
    }
    return default_text;
};

export const get_block_categories = (block, as_list=false) => {
    if(check_not_empty(block.category)) {
        if(typeof block.category === "string") {
            if(as_list) {
                return [block.category]
            }
            return block.category;
        } else {
            if(as_list) {
                return block.category
            }
            return block.category.join(" ")
        }
    }
    if(as_list) {
        return []
    }
    return "";
};


export const replace_values_in_string = (input_string, replace_values = null, language = null, general = null) => {
    if(language === null) {
        language = LANGUAGE_DUTCH;
    }
    if(typeof input_string === 'string') {
        let return_string = input_string;
        if(replace_values !== null) {
            Object.keys(replace_values).forEach((key) => {
                const replace_info = replace_values[key];
                let replace_value;
                if(typeof replace_info === "object") {
                    replace_value = replace_info.value;
                    if(replace_info.as_number !== undefined && replace_info.as_number === true) {
                        replace_value = number_to_string(replace_value, null, language);
                    }
                    if(replace_info.add_thousands_separator !== undefined && replace_info.add_thousands_separator === true) {
                        replace_value = numberWithDots(replace_value, language);
                    }
                    if(replace_info.number_as_string !== undefined && replace_info.number_as_string === true) {
                        if(general !== null && general.general_texts !== undefined && general.general_texts.number_to_text !== undefined) {
                            replace_value = int_to_text(replace_value, get_text(general, 'number_to_text',
                                null, language, null, null, null, false,
                                false, false, false,
                                true));
                        }
                    }
                    if(replace_info.suffix !== undefined && replace_info.suffix !== null && replace_info.suffix !== "") {
                        replace_value = `${replace_value}${replace_info.suffix}`;
                    }
                } else {
                    replace_value = replace_info;
                }

                return_string = return_string.replace(`[${key}]`, replace_value)
            });
        }
        return return_string;
    }
    return input_string;
}

export const get_text = (text_object, id = null, default_value = "", language=null, general = null, replace_values = null, suffix = null, as_number = false, add_thousands_separator = false, number_as_string = false, first_letter_capital=false, translation_dictionary = false) => {
    if(language === null) {
        language = LANGUAGE_DUTCH;
    }
    if(text_object !== undefined && text_object !== null) {
        let return_value;
        if(typeof text_object === 'string') {
            return_value = text_object;
        }else if(typeof text_object === 'number') {
            return_value = text_object.toString();
        } else {
            if(id !== null) {
                if(text_object instanceof Object) {
                    let actual_object_content = text_object;
                    if(actual_object_content.general_texts !== undefined) {
                        actual_object_content = text_object.general_texts
                    }
                    let id_list;
                    if(typeof id === "string") {
                        id_list = id.split(".");
                    } else {
                        id_list = id;
                    }
                    if(actual_object_content[id_list[0]] !== undefined) {
                        for(let id_index = 0; id_list.length > id_index; id_index++) {
                            const actual_index = id_list[id_index];
                            if (actual_object_content[actual_index] !== undefined) {
                                actual_object_content = actual_object_content[actual_index];
                            } else {
                                break;
                            }
                        }
                        if(actual_object_content[language] !== undefined) {
                            return_value = actual_object_content[language];
                        } else {
                            return_value = actual_object_content;
                        }
                    }
                }
            } else {
                if(text_object[language] !== undefined) {
                    return_value = text_object[language];
                } else{
                    return_value = text_object;
                }
            }
        }
        if(typeof return_value === 'string' && return_value !== "") {
            return_value = replace_values_in_string(return_value, replace_values, language, general);
            if (as_number !== undefined && as_number === true) {
                return_value = number_to_string(return_value, null, language);
            }
            if (add_thousands_separator !== undefined && add_thousands_separator === true) {
                return_value = numberWithDots(return_value, language);
            }
            if (number_as_string !== undefined && number_as_string === true) {
                if (general !== null && general.general_texts !== undefined && general.general_texts.number_to_text !== undefined) {
                    return_value = int_to_text(return_value, get_text(general, 'number_to_text', null,
                        language, null, null, null, false,
                        false, false, false, true));
                }
            }
            if (first_letter_capital !== undefined && first_letter_capital === true) {
                return_value = ucFirst(return_value);
            }
            if (suffix !== undefined && suffix !== null && suffix !== "") {
                return_value = `${return_value}${suffix}`;
            }
            return return_value;
        } else if(translation_dictionary !== undefined && translation_dictionary === true) {
            if(typeof return_value === "object") {
                const return_value_new = {...return_value}
                Object.keys(return_value).forEach((key) => {
                    return_value_new[key] = get_text(return_value[key], null, null, language);
                })
                return_value = return_value_new
            }
        }
        return return_value;
    }
    return default_value
}


export const get_filter_key = (selected_filters, block_filters) => {
    const variable_array = Array(block_filters.length);
    block_filters.forEach((filter) => {
        if(check_not_empty(filter["filter_position"])) {
            variable_array[filter["filter_position"]] = check_not_empty(selected_filters[filter.id]) ?
                selected_filters[filter.id] : "NA"
        }
    });
    return variable_array.join(".");
};


export const check_not_empty = (value) => {
    return !(value === undefined || value === null || value === "");
};


export const check_na_value = (value) => {
    if(value === undefined || value === null || value === "NaN" || value === "NA") {
        return null
    }
    return value;
};


export const check_object_is_empty = (object_value) => {
    if(typeof object_value === "object" && object_value !== null) {
        const keys = Object.keys(object_value);
        for(let value_index = 0; keys.length > value_index; value_index++) {
            const inner_value = check_object_is_empty(object_value[keys[value_index]]);
            if(check_na_value(inner_value) !== null) {
                return inner_value
            }
        }
        return null;
    } else {
        return check_na_value(object_value);
    }
};


export const get_latest_data_week_key = (block_data, week_ids, skip_weeks=0) => {
    let count = 0;
    for(let i = week_ids.length - 1; i >= 0; i--) {
        const value = check_object_is_empty(block_data[week_ids[i]]);
        if(value !== null) {
            if(skip_weeks === count) {
                return week_ids[i];
            }
            count += 1;
        }
    }
    return null;
};


export const to_bool = (value, default_value=true) => {
    if(value === undefined || value === null) {
        return default_value
    }
    return value;
};


export const get_week_number_from_key = (key) => {
    const key_string = key.split("_")[1];
    return parseInt(key_string);
};


export const has_filter = (active_filters, all_filters=false) => {
    let return_val = all_filters;
    Object.keys(active_filters).forEach((key) => {
        if(all_filters) {
            if(active_filters[key] === null) {
                return_val = false;
            }
        } else {
            if(active_filters[key] !== null) {
                return_val = true;
            }
        }
    });
    return return_val;
};


export const get_block_filter_label_by_id = (block_filters, block_id, graph_label = false) => {
    for(let index = 0; index < block_filters.length; index++) {
        const value = block_filters[index];
        if(value.id === block_id) {
            if(graph_label && check_not_empty(value.label_graph)) {
                return value.label_graph;
            }
            return value.label;
        }
    }
};


export const get_filter_string = (active_filters, block_filters, language) => {
    const labels = [];
    Object.keys(active_filters).forEach((key) => {
       if(check_not_empty(active_filters[key])) {
           const filter_object = get_object_from_list(block_filters, key);
           const label = get_block_filter_label_by_id(filter_object.options, active_filters[key], true);
           labels.push(get_text(label, null, null, language));
       }
    });
    return list_to_string(labels);
};


export const get_active_filter_keys = (active_filters) => {
    return Object.keys(active_filters).filter((key) => check_not_empty(active_filters[key]));
}


export const is_same_filter = (active_filter, selected_filter) => {
  const keys = Object.keys(active_filter);
  for(let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if(active_filter[key] !== selected_filter[key]) {
          return false;
      }
  }
  return true;
};


export const current_page_info = (location, pages, give_homepage_on_mobile_devices = true) => {

    let url = "";
    if(isMobile === false || give_homepage_on_mobile_devices === false) {
        url = location.pathname.replace("/", "")
    }
    for(let i = 0; i < pages.length; i++) {
        const page = pages[i];
        if(page.path.replace("/", "") === url) {
            return page
        }
    }
}


export const numberWithDots = (x, selectedLanguage) => {
    if(check_not_empty(x)) {
        if(selectedLanguage === LANGUAGE_DUTCH) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "";
};


export const is_mobile_check = () => {
    const DEVICE_TYPES = {
        MOBILE: 'mobile',
        TABLET: 'tablet',
        SMART_TV: 'smarttv',
        CONSOLE: 'console',
        WEARABLE: 'wearable',
        BROWSER: undefined
    };
    const UAParser = require('ua-parser-js/dist/ua-parser.min');
    const UA = new UAParser();
    const device = UA.getDevice();
    return device.type === DEVICE_TYPES.MOBILE || device.type === DEVICE_TYPES.TABLET;

};


export const isMobile = is_mobile_check();
