import PropTypes from 'prop-types'
import React, { Component } from 'react';
import BlocksPage from "../BlocksPage/BlocksPage";
import TextPage from "../TextPage/TextPage";

class PageFactory extends Component {
    /* Component which renders the right page based on the given page type.
    The implementation is based on the factory pattern
    */

    render() {
        const {data} = this.props;
        // render the right page based on the type variable
        switch (data.type) {
            case page_types.BLOCKS:
                return <BlocksPage data={data}/>;
            case page_types.TEXT:
                return <TextPage data={data}/>;
            default:
                return null;
        }
    }
}

export default PageFactory;

PageFactory.propTypes = {
  data: PropTypes.object.isRequired
};

// Available page types as constants
export const page_types = {
    BLOCKS: "blocks",
    TEXT: "text",
};
