import * as actionTypes from './actionTypes'
import {getActionObject} from "../../utilities";

const set_is_open = (is_open) => getActionObject(actionTypes.TIME_OVERVIEW_MODEL_SET_IS_OPEN, {is_open});
const set_main_block_id = (main_block_id) => getActionObject(actionTypes.TIME_OVERVIEW_MODEL_SET_MAIN_BLOCK_ID, {main_block_id: main_block_id});
export const time_overview_modal_set_selection_type = (selection_type) => getActionObject(actionTypes.TIME_OVERVIEW_MODEL_SET_SELECTION_TYPE, {selection_type: selection_type});
export const time_overview_modal_set_selected_block_id = (selected_block_id) => getActionObject(actionTypes.TIME_OVERVIEW_MODEL_SET_SELECTED_BLOCK_ID, {selected_block_id: selected_block_id});

export const time_overview_modal_set_modal_open = (main_block_id) => {
    // method to open the modal
    return (dispatch) => {
        setTimeout(() => {
            dispatch(set_main_block_id(main_block_id));
            dispatch(set_is_open(true));
        }, 200);
    }
};

export const time_overview_modal_set_modal_close = () => {
    // method to close the modal
    return (dispatch) => {
        dispatch(set_main_block_id(null));
        dispatch(set_is_open(false));
    }
};
