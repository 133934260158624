import React, {Component} from 'react';
import styles from './FilterSidebarContent.module.css';
import {get_filter_key, has_filter} from "../../../../../utilities";
import FilterButton from "../../../../UI/FilterButton/FilterButton";
import * as actions from "../../../../../store/actions";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import FilterGroupButtons from "../../../../UI/FilterGroupButtons/FilterGroupButtons";
import {
    FOOTER_REFERENCE_TYPE_MEAN,
    FOOTER_REFERENCE_TYPE_PREVIOUS
} from "../../../../../Constants/footer_block_reference_type_constants";
import ResetDataButton from "../../../../UI/ResetDataButton/ResetDataButton";
import {
    GRAPH_SELECTION_TYPE_MEAN
} from "../../../../../Constants/graph_selection_type_constants";
import TextLabel from "../../../../SupportedComponents/TextLabel/TextLabel";

class FilterSidebarContent extends Component {
    /*
    * Component to show the filter options in de sidebar
    */
    filter_button_click = (group, filter_id) => {
        // method to handle a click on a filter button

        // update the filter props
        this.props.set_active_data_filters(
            {
                ...this.props.active_data_filters,
                [group]: filter_id
            });

        // If the button click removes a filter, check if any other is filter is used. If no filter anymore is used,
        // set the block footer reference to the PREVIOUS type, because a the average cannot be showed if no filter
        // is present, because the blocks showed the average. If the user set a new filter and has earlier selected
        // the average footer type, set than the average type back
        if(filter_id === null) {
            let all_null = true;
            Object.keys(this.props.active_data_filters).forEach((key) => {
                const value = this.props.active_data_filters[key];
                if(key !== group) {
                    if(value !== null) {
                        all_null = false;
                    }
                }
            });
            if(all_null) {
                // if no filter is set, than the reference footer must showed the previous measure
                this.props.set_block_footer_reference_type(FOOTER_REFERENCE_TYPE_PREVIOUS, false);
            }
        } else {
            // if the user add a filter and selected earlier the average footer, then set the average footer back
            if(this.props.block_footer_reference_type !== this.props.block_footer_reference_type_by_user) {
                this.props.set_block_footer_reference_type(this.props.block_footer_reference_type_by_user, false);
            }
        }
    };
    reset_button_click = () => {
        // method to handle the reset button click
        // remove the filters
        this.props.init_data_filters();
        // set footer type to previous measure
        this.props.set_block_footer_reference_type(FOOTER_REFERENCE_TYPE_PREVIOUS, true);
        // set the comparison selection type to the average
        this.props.set_selection_type(GRAPH_SELECTION_TYPE_MEAN);
    }

    render() {
        const {block_filters, blocks_data, active_data_filters,
            block_footer_reference_type, set_block_footer_reference_type, blocks_data_loaded} = this.props;

        // get the number of participants of the selected filter group
        let number_of_participants = null;
        if(blocks_data_loaded) {
            number_of_participants = blocks_data[get_filter_key(active_data_filters, block_filters)]["size"];
        }

        // render the filter sidebar content
        return (
            <div className={styles.content}>
                <div className={styles.filters}>
                    {/* render the different filters */}
                    {block_filters.map((filter) => <FilterGroupButtons
                        key={filter.id}
                        filters={block_filters}
                        filter={filter}
                        active_filter={active_data_filters}
                        onChange={this.filter_button_click}
                    />)}
                </div>
                <div className={styles.other_options}>
                    <span className={styles.options_title}>
                        {/* Show the text label above the compare footer buttons. Replace the value key with
                        the number of participants*/}
                        <TextLabel use_general
                                   id="blocks_filter_compare_footer_title"
                                   replace_values={
                                       {"value":
                                               {"value": number_of_participants,
                                                   "add_thousands_separator": true}
                                       }} />
                    </span>
                    {/* Show the Footer comparison buttons */}
                    <FilterButton
                        onClick={() => set_block_footer_reference_type(FOOTER_REFERENCE_TYPE_PREVIOUS)}
                        active={block_footer_reference_type === FOOTER_REFERENCE_TYPE_PREVIOUS}
                        disabled={has_filter(active_data_filters) === false}
                    >
                        <TextLabel use_general id="compare_previous_measurement" />
                    </FilterButton>
                    <FilterButton
                        onClick={()=> set_block_footer_reference_type(FOOTER_REFERENCE_TYPE_MEAN)}
                        active={block_footer_reference_type === FOOTER_REFERENCE_TYPE_MEAN}
                        disabled={has_filter(active_data_filters)  === false}>
                        <TextLabel use_general id="compare_mean" /></FilterButton>
                    {/* Show the result button */}
                    <ResetDataButton
                        onClick={this.reset_button_click}
                        disabled={has_filter(active_data_filters)  === false}>
                        <TextLabel use_general id="remove_selection" />
                    </ResetDataButton>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        blocks_data_loaded: state.coronaBarometerReducer.blocks_data_loaded,
        block_filters: state.coronaBarometerReducer.block_filters,
        active_data_filters: state.coronaBarometerReducer.active_data_filters,
        selected_data_filters: state.coronaBarometerReducer.selected_data_filters,
        current_week: state.coronaBarometerReducer.current_week,
        data_filters: state.coronaBarometerReducer.data_filters,
        blocks_data: state.coronaBarometerReducer.blocks_data,
        block_footer_reference_type: state.coronaBarometerReducer.block_footer_reference_type,
        block_footer_reference_type_by_user: state.coronaBarometerReducer.block_footer_reference_type_by_user,
        week_ids: state.coronaBarometerReducer.week_ids,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        open_modal: (block) => dispatch(actions.time_overview_modal_set_modal_open(block)),
        set_active_data_filters: (active_data_filters) => dispatch(actions.corona_barometer_set_active_data_filters(active_data_filters)),
        set_selected_data_filters: (selected_data_filters) => dispatch(actions.corona_barometer_set_selected_data_filters(selected_data_filters)),
        init_data_filters: () => dispatch(actions.corona_barometer_init_data_filters()),
        set_selection_type: (selection_type) => dispatch(actions.time_overview_modal_set_selection_type(selection_type)),
        set_block_footer_reference_type: (footer_reference_type, by_user=true) => dispatch(actions.corona_barometer_set_block_footer_reference_type(footer_reference_type, by_user))
    }
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(FilterSidebarContent));
