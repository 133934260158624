import PropTypes from 'prop-types'
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Isotope from 'isotope-layout';

// Container for isotope grid
class BasicGridContainer extends Component {
    /*
    * The blocks layout is arranged and filtered by using the Isotope js package.
    * https://isotope.metafizzy.co/
    * The packages uses two basic blocks, the grid container (this component) and all the individual blocks (items)
    * which is implemented by the GritItem component. The plugin arrange the blocks automatically based on screen width.
    * This BasicGridContainer component do not have the functionality of filtering
    *
    * Isotope grid is implemented in this object (BasicGridContainer) and GridContainer,
    * which extends this object with block filtering.
    *
    * */
    constructor(props) {
        super(props);
        // Use a reference to the isotope object
        this.state = {
            node: null,
            isotope: null,
        };
    }

    render() {
        // Render the Isotype grid container with the grid items, given as child items
        return(
            <div className="grid" style={{position: "relative"}}>
                {this.props.children}
            </div>
        )
    }

    componentDidMount() {
        // set up isotope
        const node = ReactDOM.findDOMNode(this);
        if (!this.state.isotope) {
            // create isotope object
            this.setState({
                node: node,
                isotope: new Isotope(node, {
                    layoutMode: 'masonry',
                    percentPosition: true,
                    hiddenStyle: {
                        opacity: 0
                    },
                    visibleStyle: {
                        opacity: 1
                    }
                } )
            });
        } else {
            // Reload the items if the object is earlier created
            this.state.isotope.reloadItems();
        }
    }
}

export default BasicGridContainer;

BasicGridContainer.propTypes = {
    children: PropTypes.any // The GridItems
};
