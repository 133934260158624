import React, {Component} from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import { Provider } from 'react-redux';
import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import iconsReducer from './store/reducers/icons_reducer';
import coronaBarometerReducer from './store/reducers/coroa_barometer_reducer';
import timeOverviewModalReducer from './store/reducers/time_overview_modal_reducer';
import CoronaBarometer from "./Components/CoronaBarometer/CoronaBarometer";
import ScrollToTop from "./Components/SupportedComponents/ScrollToTop/ScrollToTop";

const reducers = combineReducers({
    iconsReducer,
    coronaBarometerReducer,
    timeOverviewModalReducer
});

const initialState = {};
let enhancer;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    const composeEnhancers =
        typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            : compose;

    enhancer = composeEnhancers(
        applyMiddleware(thunk),
    );
} else {
    enhancer = compose(
        applyMiddleware(thunk),
    );
}

const store = createStore(reducers, initialState, enhancer);

class App extends Component{
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <ScrollToTop>
                        <CoronaBarometer />
                    </ScrollToTop>
                </BrowserRouter>
            </Provider>
        );
    }
}

export default App;
