import PropTypes from 'prop-types'
import React, {Component} from 'react';
import styles from './ReferenceFooter.module.css';
import Icon from "../../Icon/Icon";

class ReferenceFooter extends Component {
    /* Component to add the reference footer to a statistic block on a blocks page.
    Used on the blocks with a single statistic */
    render() {
        const {color, arrow, children} = this.props;
        // Do not add the footer if no content is given
        if(children === undefined || children === null || children === "") {
            return null;
        }
        const footer_styles = [styles.block_footer];
        let arrow_icon = null;

        // Find the right color and arrow style class and icon name base on input
        switch (color) {
            case "positive":
                footer_styles.push(styles.bg_positive);
                switch (arrow) {
                    case "positive":
                        arrow_icon = "arrow-positive-up";
                        break;
                    case "negative":
                        arrow_icon = "arrow-positive-down";
                        break;
                    default:
                        arrow_icon = null;
                }
                break;
            case "neutral":
                footer_styles.push(styles.bg_neutral);
                switch (arrow) {
                    case "positive":
                        arrow_icon = "arrow-neutral-up";
                        break;
                    case "negative":
                        arrow_icon = "arrow-neutral-down";
                        break
                    default:
                        arrow_icon = null;
                }
                break;
            case "negative":
                footer_styles.push(styles.bg_negative);
                switch (arrow) {
                    case "positive":
                        arrow_icon = "arrow-negative-up";
                        break;
                    case "negative":
                        arrow_icon = "arrow-negative-down";
                        break;
                    default:
                        arrow_icon = null;
                }
                break;
            default:
                footer_styles.push(styles.bg_neutral);
                break;
        }

        // Render the footer
        return (
            <div className={footer_styles.join(" ")}>
                {arrow_icon ? <Icon icon={arrow_icon} className={styles.icon_arrow}/> : null}
                {this.props.children}
            </div>
        )
    }
}

export default ReferenceFooter;

ReferenceFooter.propTypes = {
  arrow: PropTypes.string, // the direction of the arrow, positive is up, negative is down
  children: PropTypes.any.isRequired, // text of the footer
  color: PropTypes.string, // the color of the footer, positive is green, negative is red, neutral is gray
};
